import { Author } from '@autocut/components/atoms/Author/Author';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { colors } from '@autocut/designSystem/colors';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import React, { useEffect } from 'react';
import { useOnboardingTour } from './OnboardingProvider';
import { OnboardingStep } from './types';

export const OnboardingTooltip = ({
  author,
  description,
  onNextStep,
  onSkip = skipOnboarding => skipOnboarding(),
  onElementClick,
  onElementDisappear,
  onElementAppear,
  disableContent = false,
  highlightTarget = true,
  ...stepProps
}: OnboardingStep) => {
  const { nextStep, completeOnboarding, quitTour } = useOnboardingTour();

  useEffect(() => {
    const unmount: (undefined | (() => void))[] = [];

    if (highlightTarget && !disableContent) {
      const disconnect = addHighlightToTarget(stepProps.target);

      unmount.push(disconnect);
    }

    if (stepProps.target && onElementClick) {
      const disconnect = addClickListenerToTarget(stepProps.target, () =>
        onElementClick(nextStep, quitTour)
      );

      unmount.push(disconnect);
    }

    if (onElementAppear) {
      for (const onAppear of onElementAppear) {
        const disconnect = addOnElementAppearListener(
          onAppear.target || [stepProps.target],
          () => onAppear.callback(nextStep, quitTour)
        );

        unmount.push(disconnect);
      }
    }

    if (onElementDisappear) {
      for (const onDisappear of onElementDisappear) {
        const disconnect = addOnElementDisappearListener(
          onDisappear.target || [stepProps.target],
          () => onDisappear.callback(nextStep, quitTour)
        );

        unmount.push(disconnect);
      }
    }

    return () => {
      unmount.forEach(disconnect => disconnect?.());
    };
  }, []);

  if (disableContent) return null;
  return (
    <FlexContainer
      style={{
        padding: '3px',
        width: '100%',

        borderRadius: '12px',
        background: `linear-gradient(45deg, hsla(16, 92%, 63%, 1) 0%, hsla(35, 83%, 61%, 1) 100%)`,
      }}
    >
      <FlexContainer
        flexDirection="column"
        gap={12}
        style={{
          padding: '12px 24px',
          width: '100%',

          borderRadius: '9px',
          background: `linear-gradient( 25deg, rgba(36, 33, 44, 0.8) 70%, rgba(36, 33, 44, 0.6755077030812324) 100%), linear-gradient( 25deg, rgba(36, 33, 44, 1) 75%, rgba(235, 91, 38, 1) 100%), linear-gradient( 25deg, rgba(36, 33, 44, 1) 68%, rgba(235, 91, 38, 0.8743872549019608) 85%, rgba(36, 33, 44, 1) 100%)`,
        }}
      >
        <FormSection title={<Author author={author} />}>
          <PaddedSection>
            <Text color={colors.gray200}>{description}</Text>
          </PaddedSection>
        </FormSection>

        <FlexContainer flexDirection="row" justifyContent="space-between">
          {onSkip !== null ? (
            <Button
              variant="tertiary"
              onClick={() => onSkip(() => completeOnboarding(false))}
              fullWidth={false}
              size="sm"
              style={{ textDecoration: 'none' }}
            >
              <TranslatedMessage id="button_skip" defaultMessage="Skip" />
            </Button>
          ) : (
            <div />
          )}

          {onNextStep ? (
            <Button
              variant="primary"
              onClick={() => onNextStep(nextStep)}
              color={colors.primary600}
              fullWidth={false}
              size="sm"
            >
              <TranslatedMessage id="onboarding_next" defaultMessage="Next" />
            </Button>
          ) : (
            <div />
          )}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

const addClickListenerToTarget = (target: string, onClick: () => void) => {
  const targetNode = document.querySelector(target) as HTMLElement;

  if (!targetNode) return;

  targetNode.addEventListener('click', onClick);

  return () => {
    targetNode.removeEventListener('click', onClick);
  };
};

const addOnElementDisappearListener = (
  targets: string[],
  callback: () => void
) => {
  const root = document.querySelector('div#root');

  if (!root) return;

  const mutationCallback: MutationCallback = () => {
    for (const target of targets) {
      const targetNode = document.querySelector(target);
      if (!targetNode) {
        callback();
        break;
      }
    }
  };

  const observer = new MutationObserver(mutationCallback);

  observer.observe(root, { childList: true, subtree: true });

  return () => {
    observer.disconnect();
  };
};

const addOnElementAppearListener = (
  targets: string[],
  callback: () => void
) => {
  const root = document.querySelector('div#root');

  if (!root) return;

  const mutationCallback: () => void = () => {
    for (const target of targets) {
      const targetNode = document.querySelector(target);
      if (targetNode) {
        callback();
        break;
      }
    }
  };

  const observer = new MutationObserver(mutationCallback);

  observer.observe(root, { childList: true, subtree: true });

  return () => {
    observer.disconnect();
  };
};

const addHighlightToTarget = (target: string) => {
  const targetNode = document.querySelector(target) as HTMLElement;
  if (!targetNode) return;
  const parentNode = targetNode.parentElement;
  if (!parentNode) return;

  const padding = 8;

  const hasScrollbar = window.innerHeight < document.body.scrollHeight;

  const targetRect = targetNode.getBoundingClientRect();
  const parentRect = parentNode.getBoundingClientRect();

  const relativeTop = targetRect.top - parentRect.top;
  const relativeLeft = targetRect.left - parentRect.left;

  const parentPosition = window.getComputedStyle(parentNode).position;
  parentNode.style.position =
    parentPosition === 'static' ? 'relative' : parentPosition;

  const outlineNode = document.createElement('div');
  outlineNode.style.position = 'absolute';

  outlineNode.style.padding = '3px';
  outlineNode.style.background =
    'linear-gradient(45deg, hsla(16, 92%, 63%, 1) 0%, hsla(35, 83%, 61%, 1) 100%)';
  outlineNode.style.borderRadius = '12px';

  outlineNode.style.top = `${relativeTop - padding}px`;
  outlineNode.style.left = `${relativeLeft - padding}px`;
  outlineNode.style.width = `calc(${targetRect.width}px + ${padding * 2}px ${
    !hasScrollbar ? '+ var(--scrollbar-width, 10px)' : ''
  })`;
  outlineNode.style.height = `${targetRect.height + padding * 2}px`;

  const backgroundNode = document.createElement('div');
  backgroundNode.style.borderRadius = '9px';
  backgroundNode.style.overflow = 'hidden';
  backgroundNode.style.background =
    'linear-gradient( 25deg, rgba(36, 33, 44, 0.8) 70%, rgba(36, 33, 44, 0.6755077030812324) 100%), linear-gradient( 25deg, rgba(36, 33, 44, 1) 75%, rgba(235, 91, 38, 1) 100%), linear-gradient( 25deg, rgba(36, 33, 44, 1) 68%, rgba(235, 91, 38, 0.8743872549019608) 85%, rgba(36, 33, 44, 1) 100%)';
  backgroundNode.style.width = '100%';
  backgroundNode.style.height = '100%';

  const zIndex = Number.isInteger(
    parseInt(window.getComputedStyle(targetNode).zIndex)
  )
    ? parseInt(window.getComputedStyle(targetNode).zIndex) + 1
    : 1;
  const outlineZIndex = zIndex - 1;

  outlineNode.style.zIndex = outlineZIndex.toString();
  targetNode.style.zIndex = zIndex.toString();
  targetNode.style.position =
    window.getComputedStyle(targetNode).position === 'static'
      ? 'relative'
      : window.getComputedStyle(targetNode).position;

  parentNode.appendChild(outlineNode);
  outlineNode.appendChild(backgroundNode);

  return () => {
    outlineNode.remove();
    backgroundNode.remove();
  };
};
