import React, { useEffect } from 'react';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import css from './BaseModal.module.scss';
import { setAutocutStore } from '@autocut/utils/zustand';
import { RxCross1 } from 'react-icons/rx';

export type ModalProps = {
  title?: string | React.ReactNode;
  icon?: React.ReactNode;
  noHeader?: boolean;
  children?: React.ReactNode;
  extraChildren?: React.ReactNode;
  footer?: React.ReactNode;
  closeModalFunction?: () => any;
  shouldScroll?: boolean;
  fullScreen?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  withCloseButton?: boolean;
  className?: string;
  closeOnClickOutside?: boolean;
  variant?: 'default' | 'outline';
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>;

const Modal = ({
  title,
  icon,
  noHeader = false,
  children,
  extraChildren,
  footer,
  closeModalFunction = () => setAutocutStore('ui.openedModalName', ''),
  shouldScroll = true,
  fullScreen = false,
  fullWidth = false,
  fullHeight = false,
  withCloseButton = true,
  className,
  closeOnClickOutside = true,
  variant = 'default',
  ...props
}: ModalProps) => {
  useEffect(() => {
    // @ts-ignore
    disableBodyScroll(document);

    return () => {
      // @ts-ignore
      enableBodyScroll(document);
    };
  }, []);

  return (
    <div
      className={`${css.container} ${fullScreen && css.fullScreen} ${
        fullWidth && css.fullWidth
      } ${fullHeight && css.fullHeight} ${className || ''}`}
      {...props}
    >
      <div
        className={css.background}
        onClick={closeOnClickOutside ? closeModalFunction : undefined}
      >
        {extraChildren}
      </div>

      <div className={css.modal} data-variant={variant}>
        <div className={css.blob} />
        <div className={css.body}>
          {!noHeader && (
            <div className={css.header}>
              <div className={css.title}>
                {icon && icon}
                {title}
              </div>
              {withCloseButton && (
                <div className={css.closeButton} onClick={closeModalFunction}>
                  <RxCross1 />
                </div>
              )}
            </div>
          )}
          <div
            className={`${css.content} ${shouldScroll ? css.shouldScroll : ''}`}
            style={{
              overflowY: shouldScroll ? 'auto' : 'hidden',
            }}
          >
            {children}
          </div>
          {footer}
        </div>
      </div>
    </div>
  );
};

export default Modal;
