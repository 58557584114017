import React, { useEffect, useState } from 'react';

import css from './ImageContainer.module.scss';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {
  NO_ACTIVE_SEQUENCE_ERROR_MESSAGE,
  exportCurrentFrameAsPNGSync,
} from '@autocut/utils/previewFrame';

import { InfoText } from '@autocut/components/atoms/InfoText';
import { LoadingRing } from '@autocut/designSystem/components/atoms/LoadingRing/LoadingRing';
import PopupPortal from '@autocut/components/atoms/PopupPortal/PopupPortal';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { IoWarningOutline } from 'react-icons/io5';
import { FaPlay } from 'react-icons/fa6';

export type ImageContainerProps = {
  viralClip: ViralClip;
  onClick?: () => void;
};

export const ImageContainer = ({ viralClip, onClick }: ImageContainerProps) => {
  const [src, setSrc] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isMissingSequence, setIsMissingSequence] = useState(false);

  const errorIconRef = React.useRef<HTMLDivElement>(null);
  const [errorIsShown, setErrorIsShown] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    void exportCurrentFrameAsPNGSync(
      src,
      viralClip.start,
      `${viralClip.title}.png`
    )
      .then(blobUrl => {
        setSrc(blobUrl);
        setIsError(false);
        setIsMissingSequence(false);
      })
      .catch((e: Error) => {
        console.log('ERROR', e);
        if (e.message.includes(NO_ACTIVE_SEQUENCE_ERROR_MESSAGE)) {
          setIsError(false);
          setIsLoading(false);
          setIsMissingSequence(true);
        } else {
          setIsError(true);
          setIsLoading(false);
          setIsMissingSequence(false);
        }
      });
  }, [viralClip]);

  if (isError || isMissingSequence) {
    return (
      <div className={css.container}>
        {errorIsShown && (
          <PopupPortal target={errorIconRef} position="top-right">
            <InfoText style="error">
              <div
                style={{
                  maxWidth: '200px',
                }}
              >
                <TranslatedMessage
                  id="chapters_preview_error"
                  defaultMessage="An error has occured generating the preview. Try to update the timestamps or to restart AutoCut if the problems persists."
                />
              </div>
            </InfoText>
          </PopupPortal>
        )}
        <FlexContainer
          ref={errorIconRef}
          className={css.errorContainer}
          alignItems="center"
          justifyContent="center"
          onMouseEnter={() => setErrorIsShown(true)}
          onMouseLeave={() => setErrorIsShown(false)}
        >
          <IoWarningOutline />
        </FlexContainer>
      </div>
    );
  }

  return (
    <div
      className={css.container}
      onClick={e => {
        e.preventDefault();
        onClick?.();
      }}
    >
      <div key={viralClip.title} className={css.imageContainer}>
        {isLoading && (
          <FlexContainer
            justifyContent="center"
            alignItems="center"
            style={{ height: '100%' }}
          >
            <LoadingRing />
          </FlexContainer>
        )}
        <img
          src={src}
          alt={viralClip.title}
          onLoad={() => {
            setIsLoading(false);
          }}
          style={{ display: isLoading ? 'none' : 'block' }}
        />
        {!isLoading && (
          <div className={css.playIcon}>
            <FaPlay size={16} />
          </div>
        )}
      </div>
    </div>
  );
};
