import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

import { ModalType } from '@autocut/enums/modals.enum';
import { CaptionChunk } from '@autocut/types/Captions';
import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import { BsEmojiSmile } from 'react-icons/bs';
import { TbNumbers } from 'react-icons/tb';

import { formatNumbersFromChunks } from '@autocut/utils/transcript/formatNumbersFromChunks.utils';
import { addModalToQueue } from '@autocut/utils/modal/modals.utils';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { colors } from '@autocut/designSystem/colors';

export const CaptionsTranscriptButtons = ({
  captionChunks,
  updateChunks,
}: {
  captionChunks: CaptionChunk[];
  updateChunks: (captionChunks: CaptionChunk[]) => void;
}) => {
  return (
    <FlexContainer flexDirection="row" alignItems="center" gap={8}>
      <Button
        onClick={() => {
          addModalToQueue([ModalType.GenerateEmojis, ModalType.EditTranscript]);
          setAutocutStore('ui.openedModalName', '');
        }}
        variant="secondary"
        fullWidth={false}
      >
        <FlexContainer flexDirection="row" alignItems="center" gap={4}>
          <BsEmojiSmile />

          <p
            style={{
              fontSize: '0.75rem',
              color: colors.gray200,
            }}
          >
            <TranslatedMessage
              id="captions_transcript_add_emojis_cta"
              defaultMessage="Generate emojis"
            />
          </p>
        </FlexContainer>
      </Button>

      <Button
        onClick={() => {
          setAutocutStore('ui.openedModalName', ModalType.Processing);
          setAutocutStore(
            'ui.cutButtonMessage',
            'process_modal_formatting_numbers'
          );

          void formatNumbersFromChunks({
            transcription: captionChunks,
          }).then(({ newTranscription, replacedWords }) => {
            setAutocutStore(
              'ui.parameters.caption.postProcessing.formattedNumbers',
              replacedWords
            );

            setAutocutStore('ui.openedModalName', ModalType.FormattedNumber);
            addModalToQueue(ModalType.EditTranscript);
            setAutocutStore('ui.cutButtonMessage', '');
            updateChunks(newTranscription);
          });
        }}
        variant="secondary"
        fullWidth={false}
      >
        <FlexContainer flexDirection="row" alignItems="center" gap={4}>
          <TbNumbers />

          <p
            style={{
              fontSize: '0.75rem',
              color: colors.gray200,
            }}
          >
            <TranslatedMessage
              id="captions_transcript_format_numbers_cta"
              defaultMessage="Format numbers"
            />
          </p>
        </FlexContainer>
      </Button>
    </FlexContainer>
  );
};
