import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { getCaptionsPresetsFromKey } from '@autocut/utils/captionsPresets/getCaptionsPresetsFromKey';
import { setAutocutStore } from '@autocut/utils/zustand';
import React, { useEffect, memo } from 'react';
import { CaptionsPresetCard } from '../../../Customization/Parts/PresetCustomization/components/CaptionsPresetCard/CaptionsPresetCard';
import { CaptionsPreset } from '@autocut/validationSchemas/caption.validationSchema';
import { handleApplyPreset } from '@autocut/modes/captions/utils/presets.captions.utils';
import css from './CaptionsPresets.module.scss';
import { Carousel } from '@autocut/components/atoms/Carousel/Carousel';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';

const PRESETS_PER_PAGE = 4;

const CaptionsPresets = () => {
  const userPresets = useAutoCutStore(
    state => state.ui.parameters.caption.userPresets
  );

  useEffect(() => {
    void getCaptionsPresetsFromKey().then(value => {
      if (value === null) {
        throw new Error('Error while getting the presets');
      }

      setAutocutStore('ui.parameters.caption.userPresets', value);
    });
  }, []);

  const groupedPresets = [...userPresets]
    .reverse()
    .reduce((acc: CaptionsPreset[][], preset, index) => {
      const groupIndex = Math.floor(index / PRESETS_PER_PAGE);
      if (!acc[groupIndex]) {
        acc[groupIndex] = [];
      }
      acc[groupIndex].push(preset as CaptionsPreset);
      return acc;
    }, []);

  const items = groupedPresets.map((group, groupIndex) => (
    <div
      key={`group-${groupIndex}`}
      className={css.presetsListContainer}
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(4, 1fr)`,
        gap: 4,
      }}
    >
      {group?.map((preset, presetIndex) => (
        <CaptionsPresetCard
          key={`group-${groupIndex}-preset-${presetIndex}`}
          preset={preset}
          onClick={() => {
            handleApplyPreset(preset);
          }}
        />
      ))}
    </div>
  ));

  return (
    <FormSection
      title={
        <TranslatedMessage
          id="captions_presets_title"
          defaultMessage="Presets"
        />
      }
      variant="secondary"
    >
      <Carousel items={items} />
    </FormSection>
  );
};

export default memo(CaptionsPresets);
