export const StatType = {
  TRIAL_PROMO_CLICKED: 'TRIAL_PROMO_CLICKED',
  STEPPER: 'STEPPER',
  HARD_RESET: 'HARD_RESET',

  // Process related events
  PROCESS_STARTED: 'PROCESS_STARTED',
  PROCESS_ENDED: 'PROCESS_ENDED',
  UNDO_PROCESS: 'UNDO_PROCESS',

  // Onboarding related events
  ON_BOARDING_COMPLETED: 'ON_BOARDING_COMPLETED',
  ON_BOARDING_SKIPPED: 'ON_BOARDING_SKIPPED',
  ON_BOARDING_STARTED: 'ON_BOARDING_STARTED',
  ON_BOARDING_QUIT: 'ON_BOARDING_QUIT',

  // Error related events
  ERROR: 'ERROR',

  // Captions
  CAPTIONS_BACK_TO_EDITOR: 'CAPTIONS_BACK_TO_EDITOR',
} as const satisfies Record<string, Uppercase<string>>;

export type StatType = (typeof StatType)[keyof typeof StatType];
