import { Camera, CameraWithScore } from '@autocut/types/Podcast';
import { Speaker } from '@autocut/types/Speaker';
import { IncrementalError } from '../errors/IncrementalError';
import { getParametersForMode } from '../parameters.utils';

export const getCamerasScore = (
  from: number,
  to: number,
  cameras: Camera[],
  speakers: (Speaker & {
    clips: Clip[];
    talkingTimeline: number[];
  })[]
): CameraWithScore[] => {
  try {
    const { precision: precisionParameter } = getParametersForMode('podcast');

    const startTick = Math.floor(from / precisionParameter);
    const endTick = Math.ceil(to / precisionParameter);

    const weights: Record<string, number> = cameras.reduce(
      (acc, value) => ({ ...acc, [value.value]: value.weight }),
      {}
    );
    const camerasWithScore: CameraWithScore[] = cameras.map(camera => ({
      ...camera,
      score: 0,
    }));

    for (const speaker of speakers) {
      if (startTick > speaker.talkingTimeline.length) {
        // We can return directly because all speaker have the same length
        return [];
      }

      const currenTimelineWindow = speaker.talkingTimeline.slice(
        startTick,
        endTick
      );

      const talkingRatio = currenTimelineWindow.reduce(
        (acc, value, _, { length }) => {
          return acc + value / length;
        },
        0
      );

      for (const camera of speaker.cameras) {
        const cameraWithScore = camerasWithScore.find(
          currentCamera => currentCamera.value === camera.videoTrack
        );

        if (cameraWithScore) {
          cameraWithScore.score += talkingRatio * weights[camera.videoTrack];
        }
      }
    }

    return camerasWithScore.sort(
      (a, b) => b.score - a.score
    ) as CameraWithScore[];
  } catch (err: any) {
    throw new IncrementalError(err, 'getCamerasScore');
  }
};
