import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import React, { useEffect, useRef } from 'react';

import css from './ProcessModal.module.css';
import { FromBottomModalLayout } from '@autocut/layouts/FromBottomModalLayout/FromBottomModalLayout';
import { LoaderAutocut } from '@autocut/components/atoms/LoaderAutocut/LoaderAutocut';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import Progress from '@autocut/components/atoms/Progress/Progress';
import { EOnboardingTargets } from '@autocut/context/OnboardingProvider/OnboardingSteps/utils';

export const ProcessModal = () => {
  const { mode, currentStep, progress } = useAutoCutStore(state => ({
    mode: state.ui.process.mode,
    currentStep: state.ui.cutButtonMessage,
    progress: state.onGoingProcess.progress,
  }));

  const progressTimeoutId = useRef<NodeJS.Timeout | null>();

  useEffect(() => {
    if (progressTimeoutId.current) {
      clearTimeout(progressTimeoutId.current);
    }

    if (progress !== undefined) {
      progressTimeoutId.current = setTimeout(() => {
        setAutocutStore('onGoingProcess.progress', undefined);
      }, 1000);
    }
  }, [progress]);

  return (
    <FromBottomModalLayout>
      <FlexContainer
        data-tour={EOnboardingTargets.PROCESS_MODAL}
        className={css.container}
        flexDirection="column"
        alignItems="center"
        gap={36}
      >
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={16}
        >
          <LoaderAutocut height={100} />
          <div className={css.modeTitle}>{mode.name}</div>
        </FlexContainer>

        <FlexContainer
          className={css.currentStep}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap={8}
        >
          {currentStep && (
            <TranslatedMessage id={currentStep} defaultMessage={currentStep} />
          )}
          {!currentStep && 'Processing...'}

          {progress && (
            <Progress
              progress={progress}
              width="max(50vw, 150%)"
              height="8px"
              color="#e65019"
            />
          )}
        </FlexContainer>
        <div className={css.warning}>
          <TranslatedMessage
            id="process_modal_warning"
            defaultMessage="Please do not do anything on Premiere Pro, it will cause some issues"
          />
        </div>
      </FlexContainer>
    </FromBottomModalLayout>
  );
};
