import { Button } from '@autocut/components/atoms';
import { ModeLayout } from '@autocut/components/atoms/ModeLayout/ModeLayout';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { InvalidSelectionScreen } from '@autocut/components/partials/InvalidSelectionScreen/InvalidSelectionScreen';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { SelectedClipsStep } from '@autocut/components/steps/SelectedClipsStep/SelectedClipsStep';
import { TranscriptLanguageStep } from '@autocut/components/steps/TranscriptLanguageStep/TranscriptLanguageStep';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useHandleProcessTranscript } from '@autocut/hooks/useHandleProcessTranscript';
import { Font } from '@autocut/types/font';
import React from 'react';

import silenceSelectionImageUrl from '@autocut/assets/images/selectionScreen/silence.gif';
const silenceSelectionImage = new URL(silenceSelectionImageUrl, import.meta.url)
  .href;

export const ViralClipsLanguage = () => {
  const { selectionInfos, languageOfTranscription, isProviderAvailable } =
    useAutoCutStore(state => ({
      selectionInfos: state.sequence.infos,
      languageOfTranscription:
        state.ui.parameters.viral_clips.languageOfTranscription,
      isProviderAvailable: state.ui.currentTranscription.isProviderAvailable,
    }));

  const { handleProcessTranscript, error, loading } =
    useHandleProcessTranscript('viral_clips');

  if (!selectionInfos || selectionInfos.nbClipsAudio === 0) {
    return (
      <InvalidSelectionScreen
        titleId="viral_clips_invalid_selection_screen_title"
        titleDefaultMessage="Select clips on your timeline on which you want to base Auto Viral Clips Detector"
        texts={[
          {
            id: 'viral_clips_invalid_selection_screen_text1',
            defaultMessage:
              'To use Auto Viral Clips Detector, you need to select at least one clip with audio in your timeline.',
          },
        ]}
        selectionImage={silenceSelectionImage}
      />
    );
  }

  const languageIsDisabledMessage =
    languageOfTranscription.unsupportedFeatures?.({} as Font)?.viralClips
      ?.disabled
      ? 'viral_clips_disabled_language'
      : undefined;

  const blockingError =
    isProviderAvailable.message ?? languageIsDisabledMessage;

  return (
    <ModeLayout
      footer={
        <ModeFooter
          forcedErrorId={error ?? blockingError}
          renderButton={({ buttonRef, isLoading, isDisabled }) => (
            <Button
              ref={buttonRef}
              disabled={isDisabled || Boolean(blockingError)}
              isLoading={loading || isLoading}
              onClickFunction={async () => {
                await handleProcessTranscript();
              }}
            >
              <TranslatedMessage
                id="viral_clips_process_transcript_button"
                defaultMessage="Generate viral clips"
              />
            </Button>
          )}
        />
      }
    >
      <TranscriptLanguageStep modeId="viral_clips" />

      <SelectedClipsStep />
    </ModeLayout>
  );
};
