import * as React from 'react';

import { Button } from '@autocut/components/atoms';
import { InfoText } from '@autocut/components/atoms/InfoText';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { PreviewZoomStep } from '@autocut/modes/autozoom/Steps/PreviewZoomStep/PreviewZoomStep';
import { SelectedClipsStep } from '@autocut/components/steps/SelectedClipsStep/SelectedClipsStep';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { ParametersGroupLayout } from '@autocut/layouts/ParametersGroupLayout/ParametersGroupLayout';
import { DynamicZoomStep } from '@autocut/modes/autozoom/Steps/DynamicZoomStep/DynamicZoomStep';
import { SmoothZoomStep } from '@autocut/modes/autozoom/Steps/SmoothZoomStep/SmoothZoomStep';
import { ZoomConstraintStep } from '@autocut/modes/autozoom/Steps/ZoomConstraintStep/ZoomConstraintStep';
import { ZoomCoverageStep } from '@autocut/modes/autozoom/Steps/ZoomCoverageStep/ZoomCoverageStep';
import { ZoomTypesStep } from '@autocut/modes/autozoom/Steps/ZoomTypesStep/ZoomTypesStep';
import { handleTrialTask } from '@autocut/utils/game/trialGamfication.util';
import { handleZoomProcess } from '@autocut/utils/zoom/handleZoomProcess';
import { AiOutlineZoomIn } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { ModeWrapper } from '../ModeWrapper';
import { ZoomPresetStep } from './Steps/ZoomPresetStep/ZoomPresetStep';

import silenceSelectionImageUrl from '@autocut/assets/images/selectionScreen/silence.gif';
import { Divider } from '@autocut/designSystem/components/atoms/Divider/Divider';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
const silenceSelectionImage = new URL(silenceSelectionImageUrl, import.meta.url)
  .href;

export const AutoZoomMode = () => {
  const selectionInfos = useAutoCutStore(state => state.sequence.infos);
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={AiOutlineZoomIn}
      text="AutoZoom"
      displayCondition={
        !!(
          selectionInfos &&
          selectionInfos.nbClipsAudio > 0 &&
          selectionInfos.nbClipsVideo > 0
        )
      }
      invalidSelectionScreen={{
        titleId: 'autozoom_invalid_selection_screen_title',
        titleDefaultMessage: 'Select at least 2 clips in your timeline',
        texts: [
          {
            id: 'autozoom_invalid_selection_screen_text1',
            defaultMessage:
              'To use AutoZoom, select all the clips that make up your video in your timeline.',
          },
          {
            id: 'text_noClip_no_selection',
            defaultMessage:
              'If the selected clips do not appear, try restarting AutoCut.',
          },
        ],
        selectionImage: silenceSelectionImage,
      }}
    >
      <InfoText
        style="info"
        descriptionDefault="AutoZoom will automatically add zoom to each clip selected based on decibels."
        descriptionId="autozoom_info_text"
      />

      <PreviewZoomStep />

      <Divider />

      <ZoomPresetStep />

      <FlexContainer
        style={{ paddingTop: Spacing.s4, paddingBottom: Spacing.s4 }}
      >
        <ParametersGroupLayout
          withPaddedSection={false}
          contentContainerStyle={{ paddingLeft: 'initial' }}
        >
          <ZoomTypesStep />
          <ZoomCoverageStep />
        </ParametersGroupLayout>
      </FlexContainer>

      <SmoothZoomStep />
      <DynamicZoomStep />
      <ZoomConstraintStep />

      <FlexContainer style={{ paddingTop: Spacing.s4 }}>
        <SelectedClipsStep />
      </FlexContainer>
      <ModeFooter
        renderButton={({ buttonRef, isLoading, isDisabled }) => (
          <Button
            ref={buttonRef}
            isLoading={isLoading}
            disabled={isDisabled}
            onClickFunction={async () => {
              await handleZoomProcess(intl);
              await handleTrialTask('zoom');
            }}
          >
            <TranslatedMessage id="autozoom_button" defaultMessage="Zoom" />
          </Button>
        )}
      />
    </ModeWrapper>
  );
};
