import { Button } from '@autocut/components/atoms/Buttons/Button';
import { InfoText } from '@autocut/components/atoms/InfoText';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import BleepSoundChoiceStep from '@autocut/components/steps/BleepSoundChoiceStep/BleepSoundChoiceStep';
import { SelectedClipsStep } from '@autocut/components/steps/SelectedClipsStep/SelectedClipsStep';
import { TranscriptLanguageStep } from '@autocut/components/steps/TranscriptLanguageStep/TranscriptLanguageStep';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { handleTrialTask } from '@autocut/utils/game/trialGamfication.util';
import React from 'react';
import { BiMessageSquareX } from 'react-icons/bi';
import { useIntl } from 'react-intl';
import { ModeWrapper } from '../ModeWrapper';
import { setAutocutStore } from '@autocut/utils/zustand';
import { handleFinalMessage } from '@autocut/utils/cutButton/handleFinalMessage';
import { END_MODAL } from '@autocut/utils/process/handleProcessEnd';

import silenceSelectionImageUrl from '@autocut/assets/images/selectionScreen/silence.gif';
import { useHandleProcessTranscript } from '@autocut/hooks/useHandleProcessTranscript';
const silenceSelectionImage = new URL(silenceSelectionImageUrl, import.meta.url)
  .href;

export const SwearWordsMode = () => {
  const intl = useIntl();
  const { selectionInfos, isProviderAvailable } = useAutoCutStore(state => ({
    selectionInfos: state.sequence.infos,
    isProviderAvailable: state.ui.currentTranscription.isProviderAvailable,
  }));

  const { handleProcessTranscript, error, loading } =
    useHandleProcessTranscript('swear_word', async () => {
      await handleTrialTask('swear_word');
      handleFinalMessage(
        intl,
        'button_Success',
        'Done ! Process ended successfully'
      );

      setAutocutStore('ui.openedModalName', END_MODAL['swear_word']);
      setAutocutStore('sequence.infos', undefined);
    });

  const blockingError = error ?? isProviderAvailable.message;

  return (
    <ModeWrapper
      Icon={BiMessageSquareX}
      text="AutoProfanity Filter"
      displayCondition={!!selectionInfos && selectionInfos.nbClipsAudio > 0}
      invalidSelectionScreen={{
        titleId: 'swear_words_invalid_selection_screen_title',
        titleDefaultMessage:
          'Select clips on your timeline on which you want to bleep profanities',
        texts: [
          {
            id: 'swear_words_invalid_selection_screen_text1',
            defaultMessage:
              'To use AutoProfanity Filter, you need to select at least one clip with audio in your timeline.',
          },
        ],
        selectionImage: silenceSelectionImage,
      }}
    >
      <div
        style={{
          marginBottom: 16,
        }}
      >
        <InfoText
          style="info"
          onClose={() => {
            return;
          }}
        >
          <TranslatedMessage
            id="swear_words_text_info"
            defaultMessage="Swear Words Bleeper, thanks to AI, analyzes your audio and automatically adds {br} bleeping sounds on top of profanities."
          />
        </InfoText>
      </div>

      <TranscriptLanguageStep modeId="swear_word" />
      <BleepSoundChoiceStep />
      <SelectedClipsStep />

      <ModeFooter
        forcedErrorId={blockingError}
        renderButton={({ buttonRef, isLoading, isDisabled }) => (
          <Button
            ref={buttonRef}
            isLoading={isLoading || loading}
            disabled={isDisabled || Boolean(blockingError)}
            onClickFunction={async () => {
              await handleProcessTranscript();
            }}
          >
            <TranslatedMessage
              id="swear_words_button"
              defaultMessage="Remove profanities"
            />
          </Button>
        )}
      />
    </ModeWrapper>
  );
};
