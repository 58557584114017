import * as React from 'react';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { addSpeakerReducer } from '@autocut/utils/speakers/addSpeakerReducer';
import { setAutocutStore } from '@autocut/utils/zustand';
import { SpeakerComponent } from './Speaker';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Speaker } from '@autocut/types/Speaker';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';

import css from './SpeakersStep.module.scss';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { FaPlus } from 'react-icons/fa6';

export const SpeakersStep = () => {
  const speakers = useAutoCutStore(
    state => state.ui.parameters.podcast.speakers
  );

  return (
    <FormSection
      title={
        <TranslatedMessage
          id="text_Step1Podcast_Title"
          defaultMessage="Speakers"
        />
      }
      description={
        <TranslatedMessage
          id="helper_speakers_podcast"
          defaultMessage="For each audio track, you can type the name(s) of the speaker(s) on it."
        />
      }
    >
      <PaddedSection>
        <FlexContainer
          className={css.speakersContainer}
          flexDirection="column"
          gap={8}
        >
          {speakers.map((speaker: Speaker, index: number) => (
            <SpeakerComponent
              speaker={speaker}
              index={index}
              key={speaker.id}
            />
          ))}

          <Button
            variant="secondary.dashed"
            onClick={() => {
              setAutocutStore(
                'ui.parameters.podcast.speakers',
                addSpeakerReducer
              );
            }}
          >
            <FlexContainer
              flexDirection="row"
              gap={8}
              justifyContent="center"
              alignItems="center"
            >
              <FaPlus size={16} color="white" />
              <TranslatedMessage
                id="text_podcast_add_speaker"
                defaultMessage="Add a speaker"
              />
            </FlexContainer>
          </Button>
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};
