import * as React from 'react';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';
import { Preset } from '@autocut/components/atoms/PresetCard/PresetCard';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Badge } from '@autocut/designSystem/components/atoms/Badge/Badge';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import { Input } from '@autocut/designSystem/components/atoms/Input/Input';

const podcastPresets: Preset<'podcast'>[] = [
  {
    id: 'calm',
    parameters: {
      minimumCamTime: 5,
      maximumCamTime: 60,
    },
  },
  {
    id: 'paced',
    parameters: {
      minimumCamTime: 4,
      maximumCamTime: 30,
    },
  },
  {
    id: 'energetic',
    parameters: {
      minimumCamTime: 3,
      maximumCamTime: 20,
    },
  },
  {
    id: 'hyperactive',
    parameters: {
      minimumCamTime: 2,
      maximumCamTime: 10,
    },
  },
];

export const CameraTimeStep = () => {
  const podcastParameters = useAutoCutStore(
    state => state.ui.parameters.podcast
  );

  const applyPreset = (preset: Preset<'podcast'>) => {
    const updatedParameters = {
      ...podcastParameters,
      ...preset.parameters,
    };
    setAutocutStore('ui.parameters.podcast', updatedParameters);
  };

  return (
    <FormSection
      title={
        <TranslatedMessage
          id="text_step1_podcast_title"
          defaultMessage="Camera time durations"
        />
      }
      description={
        <TranslatedMessage
          id="text_step1_podcast_description"
          defaultMessage="Define the minimum and maximum duration a camera will be displayed before switching."
        />
      }
    >
      <PaddedSection gap={20}>
        <FormSection
          title={
            <Text variant="textSm.semibold">
              <TranslatedMessage
                id="podcast_preset_title"
                defaultMessage="Camera time presets"
              />
            </Text>
          }
        >
          <FlexContainer flexDirection="row" gap={8}>
            {podcastPresets.map(preset => (
              <Badge
                hoverable={true}
                onClick={() => applyPreset(preset)}
                key={preset.id}
              >
                <TranslatedMessage
                  id={`podcast_preset_${preset.id}`}
                  defaultMessage="PRESET"
                />
              </Badge>
            ))}
          </FlexContainer>
        </FormSection>

        <FlexContainer flexDirection="row" gap={24}>
          <Input
            value={podcastParameters.minimumCamTime}
            onChange={value => {
              const numberValue = Number(value);
              const actualValue = isNaN(numberValue) ? 0 : numberValue;
              setAutocutStore(
                'ui.parameters.podcast.minimumCamTime',
                actualValue
              );
            }}
            defaultValue={3}
            unit={
              <TranslatedMessage id="secondUnit" defaultMessage="seconds" />
            }
            label={{
              text: (
                <Text variant="textSm.semibold">
                  <TranslatedMessage
                    id="text_step1_podcast_minimum"
                    defaultMessage="Minimum camera duration"
                  />
                </Text>
              ),
              helper: (
                <TranslatedMessage
                  id="helper_Step1_podcast_minium"
                  defaultMessage="Minimum duration a camera will be displayed without switching."
                />
              ),
            }}
          />
          <Input
            value={podcastParameters.maximumCamTime}
            onChange={value => {
              const numberValue = Number(value);
              const actualValue = isNaN(numberValue) ? 0 : numberValue;
              setAutocutStore(
                'ui.parameters.podcast.maximumCamTime',
                actualValue
              );
            }}
            defaultValue={20}
            unit={
              <TranslatedMessage id="secondUnit" defaultMessage="seconds" />
            }
            label={{
              text: (
                <Text variant="textSm.semibold">
                  <TranslatedMessage
                    id="text_step1_podcast_maximum"
                    defaultMessage="Maximum camera duration"
                  />
                </Text>
              ),
              helper: (
                <TranslatedMessage
                  id="helper_Step1_podcast_maximum"
                  defaultMessage="Maximum duration a camera will be displayed before switching"
                />
              ),
            }}
          />
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};
