import React, { useEffect, useState } from 'react';

import { CustomSelectText } from '@autocut/components/atoms/CustomSelectText/CustomSelectText';
import { setAutocutStore } from '@autocut/utils/zustand';
import { defaultFont, loadUserLocalFonts } from '../Fonts/fonts.utils';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { Font } from '@autocut/types/font';
import { FaSyncAlt } from 'react-icons/fa';
import { Button } from '@autocut/components/atoms';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

export const FontSelect = () => {
  const [loading, setLoading] = useState(false);
  const [fonts, setFonts] = useState<Font[]>([]);
  const { paramFont, fonts: preloadedFonts } = useAutoCutStore(state => ({
    paramFont: state.ui.parameters.caption.text.font,
    fonts: state.fonts,
  })) ?? { paramFont: defaultFont };
  const [selectedFont, setSelectedFont] = useState<Font>(
    paramFont ?? defaultFont
  );

  useEffect(() => {
    if (!!preloadedFonts.length) {
      return setFonts(preloadedFonts);
    }
    const process = async () => {
      setLoading(true);
      setTimeout(async () => {
        setFonts(await loadUserLocalFonts());
        setLoading(false);
      }, 50);
    };

    void process();
  }, [preloadedFonts]);

  return (
    <FlexContainer
      gap={4}
      flexDirection="row"
      flexWrap="nowrap"
      alignItems="center"
    >
      <CustomSelectText
        customization="bleepSelect"
        isSearchable
        options={fonts.map(font => ({
          value: font.postscriptName,
          label: font.label,
        }))}
        handleSelectChange={font => {
          const correspondingFont = fonts.find(
            fontItem => fontItem.postscriptName === font?.value
          );
          setSelectedFont(correspondingFont ?? defaultFont);
          setAutocutStore(
            'ui.parameters.caption.text.font',
            correspondingFont ?? defaultFont
          );
        }}
        initialValue={{
          value: selectedFont.postscriptName,
          label: selectedFont.label,
        }}
        isLoading={loading}
        portalTarget={document.body}
      />
      <Button
        onClickFunction={loadUserLocalFonts}
        compact={true}
        color="transparent"
      >
        <FlexContainer
          style={{
            width: '16px',
            height: '16px',
          }}
          alignItems="center"
          justifyContent="center"
        >
          <FaSyncAlt size={16} color="#F2F4F7" />
        </FlexContainer>
      </Button>
    </FlexContainer>
  );
};
