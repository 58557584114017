import { AutocutModes } from '@autocut/enums/modes.enum';
import {
  CaptionsParameters,
  CaptionsPreset,
} from '@autocut/types/CaptionsParameters';
import { removeUnwantedKeysFromCaptionsParameters } from '@autocut/utils/captions/utils';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { merge } from 'lodash';
import { syncSelectedFont } from '../Steps/Customization/Parts/Fonts/fonts.utils';
import { ModalType } from '@autocut/enums/modals.enum';
import { addModalToQueue } from '@autocut/utils/modal/modals.utils';

export const encodeCaptionParams = (params: CaptionsParameters) => {
  const stringifiedParams = JSON.stringify(params);

  const encodedParams = Buffer.from(stringifiedParams, 'utf8').toString(
    'base64'
  );

  return encodedParams;
};

export const decodeCaptionParams = (
  encodedParams: string
): CaptionsParameters => {
  const decodedParams = Buffer.from(encodedParams, 'base64').toString('utf8');

  const params = JSON.parse(decodedParams);

  return params;
};

export const handleApplyPreset = (preset: CaptionsPreset) => {
  const currentParameters = autocutStoreVanilla().ui.parameters.caption;

  //Deep clone with readonly modifier removed
  const defaultParameters = JSON.parse(
    JSON.stringify(AutocutModes.Captions.defaultParameters)
  );

  const formattedDefaultParams =
    removeUnwantedKeysFromCaptionsParameters(defaultParameters);
  const formattedPresetParams = removeUnwantedKeysFromCaptionsParameters(
    preset.params as CaptionsParameters
  );

  const finalParameters = merge(
    JSON.parse(JSON.stringify(currentParameters)),
    formattedDefaultParams,
    formattedPresetParams
  );

  const syncedFont = syncSelectedFont(finalParameters.text.font);

  if (!syncedFont) {
    addModalToQueue(ModalType.NotFoundFontModal);
  } else {
    finalParameters.text.font = syncedFont;
  }

  setAutocutStore('ui.parameters.caption', finalParameters);
};
