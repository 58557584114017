import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import React from 'react';
import { EOnboardingModes, OnboardingStep, OnboardingTourProps } from './types';
import {
  getLocalStorage,
  setLocalStorage,
} from '@autocut/utils/localStorage.utils';
import Joyride, { Step } from 'react-joyride';
import { autocutStoreVanilla } from '@autocut/utils/zustand';
import { handleTrialTask } from '@autocut/utils/game/trialGamfication.util';
import { addModalToQueue } from '@autocut/utils/modal/modals.utils';
import { ModalType } from '@autocut/enums/modals.enum';

import { OnboardingTooltip } from './OnboardingTooltip';
import { ONBOARDING_TOURS } from './OnboardingTours';
import { sendStats } from '@autocut/utils';
import { StatType } from '@autocut/types/StatType.enum';

type OnboardingContext = {
  onboardingCompleted: string[];
  completeOnboarding: (finished: boolean) => Promise<void>;
  selectedTour: OnboardingTourProps | null;
  selectTour: (onboardingTour: OnboardingTourProps | null) => void;
  openTour: (isOpen: boolean) => void;
  tourIsOpen: boolean;
  stepIndex: number;
  setStepIndex: Dispatch<SetStateAction<number>>;
  currentOnboardingId: EOnboardingModes | null;
  setCurrentOnboardingId: Dispatch<SetStateAction<EOnboardingModes | null>>;
};
export const OnboardingContext = createContext<OnboardingContext>({
  onboardingCompleted: [],
  completeOnboarding: async () => {
    return;
  },
  selectedTour: null,
  selectTour: () => {
    return;
  },
  openTour: () => {
    return;
  },
  tourIsOpen: false,
  stepIndex: 0,
  setStepIndex: () => {
    return;
  },
  currentOnboardingId: null,
  setCurrentOnboardingId: () => {
    return;
  },
});

export const OnboardingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [onboardingCompleted, setOnboardingCompleted] = useState<
    EOnboardingModes[]
  >(getLocalStorage('onboardings') || []);
  const [selectedTour, setSelectedTour] =
    React.useState<OnboardingTourProps | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentOnboardingId, setCurrentOnboardingId] =
    useState<EOnboardingModes | null>(null);

  const [stepIndex, setStepIndex] = useState(0);

  const completeOnboarding = async (finished: boolean) => {
    if (!currentOnboardingId) return;

    const onBoardingCompleted = [
      ...new Set([...onboardingCompleted, currentOnboardingId]),
    ];
    setOnboardingCompleted(onBoardingCompleted);
    setLocalStorage('onboardings', onBoardingCompleted);
    setSelectedTour(null);
    setIsOpen(false);
    setCurrentOnboardingId(null);
    setStepIndex(0);

    const traits = {
      onboardingId: currentOnboardingId,
    };

    if (finished) {
      await sendStats({
        type: StatType.ON_BOARDING_COMPLETED,
        value: 1,
        traits,
      });
    } else {
      await sendStats({
        type: StatType.ON_BOARDING_SKIPPED,
        value: isOpen ? stepIndex : -1,
        traits,
      });
    }

    if (
      finished &&
      autocutStoreVanilla()
        .game.trial.tasks.map(task => task.id)
        .includes(currentOnboardingId) &&
      autocutStoreVanilla().user.license?.type === 'trial'
    ) {
      await handleTrialTask(currentOnboardingId as any);
      addModalToQueue(ModalType.GamingTrial);
    }
  };

  return (
    <OnboardingContext.Provider
      value={{
        onboardingCompleted,
        completeOnboarding,
        selectedTour,
        selectTour: setSelectedTour,
        openTour: setIsOpen,
        tourIsOpen: isOpen,
        stepIndex,
        setStepIndex,
        currentOnboardingId,
        setCurrentOnboardingId,
      }}
    >
      {!!selectedTour && !!selectedTour.steps && (
        <Joyride
          steps={selectedTour.steps as Array<Step>}
          tooltipComponent={props => {
            const step = props.step as unknown as OnboardingStep;
            if (!step) return null;
            return <OnboardingTooltip {...step} />;
          }}
          stepIndex={stepIndex}
          continuous
          run={isOpen}
          disableCloseOnEsc
          disableOverlayClose
          disableOverlay
          debug
          callback={data => {
            if (
              data.action !== 'start' ||
              !(data.step as unknown as OnboardingStep).isSkipped
            )
              return;

            if (
              (data.step as unknown as OnboardingStep).isSkipped!(
                autocutStoreVanilla()
              )
            ) {
              setStepIndex(prev => prev + 1);
            }
          }}
          floaterProps={{
            hideArrow: true,

            styles: {
              floater: {
                width: '90%',
              },
            },
          }}
        />
      )}
      {children as React.ReactElement}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = (id: EOnboardingModes) => {
  const {
    onboardingCompleted,
    completeOnboarding,
    selectTour,
    setCurrentOnboardingId,
    stepIndex,
  } = React.useContext(OnboardingContext);
  const isOnboardingCompleted = onboardingCompleted.includes(id);

  const tour = ONBOARDING_TOURS[id];

  const openedModalName = autocutStoreVanilla().ui.openedModalName;

  useEffect(() => {
    /** Quand on ouvre la modale, le useEffect se recharge et on met la modale une deuxième fois dans la queue.
    Il faudrait pouvoir checker le nom de la modale ouverte pour ne pas la remettre dans la queue */

    const isOnboardingJustCompleted =
      getLocalStorage('onboardings')?.includes(id);

    if (
      !isOnboardingCompleted &&
      !isOnboardingJustCompleted &&
      stepIndex === 0
    ) {
      setCurrentOnboardingId(id);
      selectTour(tour);

      if (tour.openTour && openedModalName !== 'OnboardingWelcome') {
        tour.openTour();
      }
    }
  }, []);

  return {
    isOnboardingCompleted,
    tour,
    completeOnboarding,
  };
};

export const useCompleteOnboarding = () => {
  const { completeOnboarding } = useContext(OnboardingContext);

  return completeOnboarding;
};

export const useLaunchTour = () => {
  const { openTour } = useContext(OnboardingContext);

  return () => openTour(true);
};

export const useOnboardingTour = () => {
  const {
    stepIndex,
    setStepIndex,
    selectTour,
    currentOnboardingId,
    setCurrentOnboardingId,
    openTour,
    selectedTour,
    completeOnboarding,
  } = useContext(OnboardingContext);

  const nextStep = async () => {
    if (!selectedTour?.steps) return;

    if (stepIndex >= selectedTour.steps.length - 1) {
      await completeOnboarding(true);
    } else {
      setStepIndex(prev => prev + 1);
    }
  };

  const quitTour = async () => {
    selectTour(null);
    setCurrentOnboardingId(null);
    openTour(false);
    setStepIndex(0);

    if (currentOnboardingId) {
      await sendStats({
        type: StatType.ON_BOARDING_QUIT,
        value: stepIndex,
        traits: {
          onboardingId: currentOnboardingId,
        },
      });
    }
  };

  return { stepIndex, nextStep, completeOnboarding, quitTour };
};
