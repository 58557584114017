import { Input } from '@autocut/designSystem/components/atoms/Input/Input';
import React, { useState } from 'react';
import { useSearchBox } from 'react-instantsearch';
import { useIntl } from 'react-intl';

export const PresetSearchInput = () => {
  const intl = useIntl();
  const [search, setSearch] = useState<string>('');

  const { refine } = useSearchBox();

  return (
    <Input
      value={search}
      onChange={e => {
        setSearch(e);
        refine(e);
      }}
      placeholder={intl.formatMessage({
        id: 'captions_presetCustomization_createPresetModal_placeholder',
        defaultMessage: 'Search a preset',
      })}
    />
  );
};
