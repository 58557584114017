import * as React from 'react';

import { useLogger } from '@autocut/hooks/useLogger';
import { fs, os, path } from '@autocut/lib/cep/node';
import { Button } from '../../atoms';
import css from './StorageManager.module.css';
import { setLocalStorage } from '@autocut/utils/localStorage.utils';

const StorageManager = () => {
  const [message, setMessage] = React.useState<string>('');
  const { logPath } = useLogger('StorageManager');

  return (
    <div className={css.storage_manager}>
      <div className={css.footer}>
        <Button
          size="sm"
          onClickFunction={() => {
            try {
              window.localStorage.clear();
              setMessage('Local storage removed successfully');
            } catch (error) {
              //@ts-ignore
              setMessage(error.message);
            }
          }}
        >
          Remove local storage
        </Button>
        <Button
          size="sm"
          onClickFunction={() => {
            try {
              const persistancePath = path.join(
                path.dirname(logPath),
                'persistenceStorage.log'
              );
              if (fs.existsSync(persistancePath)) {
                fs.rmSync(persistancePath);
                setMessage('Persistance storage removed successfully');
              } else {
                setMessage('Persistance storage does not exist');
              }
            } catch (error) {
              //@ts-ignore
              setMessage(error.message);
            }
          }}
        >
          Remove persistance storage
        </Button>
        <Button
          size="sm"
          onClickFunction={async () => {
            try {
              const tempFolderpath = os.tmpdir();
              const extensionTempPath = path.join(tempFolderpath, 'AutoCut');

              if (fs.existsSync(extensionTempPath)) {
                const fileList = fs.readdirSync(extensionTempPath);
                for (const file of fileList) {
                  fs.rmSync(path.join(extensionTempPath, file));
                }
                setMessage("Extension's temporary files removed successfully");
              } else {
                setMessage("Extension's temporary files does not exist");
              }
            } catch (error) {
              //@ts-ignore
              setMessage(error.message);
            }
          }}
        >
          Remove temporary files
        </Button>
      </div>

      <Button onClickFunction={() => setLocalStorage('onboardings', [])}>
        Reset onboardings
      </Button>
      {message && <div className={css.message}>{message}</div>}
    </div>
  );
};

export default StorageManager;
