import { Env } from '@autocut/utils/currentEnv.utils';

const AUTOCUT_CONSTANTS = {
  [Env.Development]: {
    SERVER_ADDRESS: 'https://a-api.a1nj0fyx4.rodeo',
    WEBAPP_ADDRESS: 'http://localhost:3000',
    SELF_ADDRESS: 'http://localhost:3000',
    JULY_ADDRESS: 'https://july-api-staging.onrender.com',
    WEBSITE_ADDRESS: 'https://autocut.fr',
    ACADEMY_ADDRESS: {
      FR: 'http://localhost:3001/',
      EN: 'http://localhost:3001/en/',
    },
    GTM_ID: 'GTM-MHZ88FG',
    JULY_API_KEY: 'sF9bSeLtJYNRkiFodE7dhbdL7boQ5i8bHCkXFndK',
    JULY_SECRET_KEY: 'GZouSDLspL2KL6MM%okWB@#QJK^n@Hs',
    ALGOLIA_PROJECT_ID: 'ZVSLMFJH8J',
    ALGOLIA_API_KEY: '03e75d140c544402cac0c07982ebbfe1',
    ALGOLIA_PUBLIC_CAPTIONS_PRESETS_INDEX: 'public-captions-presets-dev',
  },
  [Env.Production]: {
    SERVER_ADDRESS: 'https://api.autocut.fr/',
    WEBAPP_ADDRESS: 'https://www.autocut.fr/app',
    SELF_ADDRESS: 'https://v3.autocut.fr',
    JULY_ADDRESS: 'https://july.autocut.fr',
    WEBSITE_ADDRESS: 'https://autocut.fr',
    ACADEMY_ADDRESS: {
      FR: 'https://autocut.fr/academy/',
      EN: 'https://autocut.fr/en/academy/',
    },
    GTM_ID: 'GTM-KWW4TP7',
    JULY_API_KEY: 'RftDDTeX8DfxXXXsTeqQyekmiR3SogCGncyodgBS',
    JULY_SECRET_KEY: 'GZouSDLspL2KL6MM%okWB@#QJK^n@Hs',
    ALGOLIA_PROJECT_ID: 'ZVSLMFJH8J',
    ALGOLIA_API_KEY: '03e75d140c544402cac0c07982ebbfe1',
    ALGOLIA_PUBLIC_CAPTIONS_PRESETS_INDEX: 'public-captions-presets',
  },
  [Env.Staging]: {
    SERVER_ADDRESS: '__SERVER_ADDRESS__',
    WEBAPP_ADDRESS: '__WEBAPP_ADDRESS__',
    SELF_ADDRESS: 'https://staging.v3.autocut.fr',
    JULY_ADDRESS: 'https://july-api-staging.onrender.com',
    WEBSITE_ADDRESS: '__WEBSITE_ADDRESS__',
    ACADEMY_ADDRESS: {
      FR: 'https://academy-staging.autocut.fr/',
      EN: 'https://academy-staging.autocut.fr/en/',
    },
    GTM_ID: 'GTM-MHZ88FG',
    JULY_API_KEY: 'sF9bSeLtJYNRkiFodE7dhbdL7boQ5i8bHCkXFndK',
    JULY_SECRET_KEY: 'GZouSDLspL2KL6MM%okWB@#QJK^n@Hs',
    ALGOLIA_PROJECT_ID: 'ZVSLMFJH8J',
    ALGOLIA_API_KEY: '03e75d140c544402cac0c07982ebbfe1',
    ALGOLIA_PUBLIC_CAPTIONS_PRESETS_INDEX: 'public-captions-presets-dev',
  },
  [Env.Test]: {
    SERVER_ADDRESS: 'http://localhost:8000/',
    WEBAPP_ADDRESS: 'http://localhost:3000',
    SELF_ADDRESS: 'http://localhost:3000',
    JULY_ADDRESS: 'https://staging.july.autocut.fr',
    WEBSITE_ADDRESS: 'http://localhost:5000',
    ACADEMY_ADDRESS: {
      FR: 'http://localhost:3001/',
      EN: 'http://localhost:3001/en/',
    },
    GTM_ID: 'GTM-MHZ88FG',
    JULY_API_KEY: 'sF9bSeLtJYNRkiFodE7dhbdL7boQ5i8bHCkXFndK',
    JULY_SECRET_KEY: 'GZouSDLspL2KL6MM%okWB@#QJK^n@Hs',
    ALGOLIA_PROJECT_ID: 'ZVSLMFJH8J',
    ALGOLIA_API_KEY: '03e75d140c544402cac0c07982ebbfe1',
    ALGOLIA_PUBLIC_CAPTIONS_PRESETS_INDEX: 'public-captions-presets-dev',
  },
} satisfies {
  [key in Env]: any;
};

export { AUTOCUT_CONSTANTS };
