import logLevel from '@autocut/types/logLevel.enum';
import { logger } from '../logger';
import { getParametersForMode } from '../parameters.utils';
import { autocutStoreVanilla } from '../zustand';
import { Camera } from '@autocut/types/Podcast';

export const getUsedVideoTracks = (): Camera[] => {
  const videoTracks = autocutStoreVanilla().sequence.infos?.videoTracks;
  if (!videoTracks) {
    logger(
      'getUsedVideoTracks',
      logLevel.debug,
      `no videoTracks returning empty array`
    );
    return [];
  }

  const availableVideoTracks = videoTracks?.map(videoTrack => {
    const nameParts = videoTrack.id.split(' ');
    const name = `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1]}`;
    return {
      label: name,
      value: `${videoTrack.index}`,
    };
  });
  const { speakers } = getParametersForMode('podcast');

  const usedCameras = availableVideoTracks.filter(availableVideoTrack => {
    for (const speaker of speakers) {
      for (const camera of speaker.cameras) {
        if (camera.videoTrack === availableVideoTrack.value) {
          return true;
        }
      }
    }
    return false;
  });
  logger(
    'getUsedVideoTracks',
    logLevel.debug,
    `data : ${JSON.stringify({
      usedCameras,
      availableVideoTracks,
    })}`
  );

  return usedCameras.map(availableVideoTrack => {
    let cameraSpeakers = 0;
    for (const speaker of speakers) {
      for (const camera of speaker.cameras) {
        if (camera.videoTrack === availableVideoTrack.value) {
          cameraSpeakers++;
        }
      }
    }
    return {
      ...availableVideoTrack,
      weight: (1 / cameraSpeakers + Math.log(cameraSpeakers)) / cameraSpeakers,
    };
  });
};
