import { AutocutModeIds } from '@autocut/enums/modes.enum';

type IsKeyValidResponse =
  | {
      result: false;
      message: string;
      licence?: Licence;
      additionalInformations?: {
        displayOnboarding?: boolean;
        latestInvoice?: string;
      };
    }
  | {
      result: true;
      message: string;
      licence: Licence;
      additionalInformations?: {
        displayOnboarding?: boolean;
        latestInvoice?: string;
      };
      accessTokens: {
        accessToken: string;
        refreshToken: string;
      };
    };

export type Licence = {
  key?: string;
  type: 'paid' | 'trial';
  features?: AutocutModeIds[] & OtherFeatures[];
  key_type?: LicenceKeyType;
  left?: number;
};

export const orderedLicenceKeyTypes = ['basic', 'ai', 'pro', 'expert'] as const;

export type LicenceKeyType = (typeof orderedLicenceKeyTypes)[number];

export type OtherFeatures = 'transition';

export default IsKeyValidResponse;
