import React, { useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';
import { debounce } from 'lodash';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { Slider } from '@autocut/designSystem/components/atoms/Slider/Slider';
import { IconVolumeMin } from '@autocut/designSystem/components/atoms/Icon/media/IconVolumeMin';
import { IconVolumeMax } from '@autocut/designSystem/components/atoms/Icon/media/IconVolumeMax';

export type NoiseLevelStepProps = {
  modeId?: 'silence';
};

export const NoiseLevelStep = ({ modeId = 'silence' }: NoiseLevelStepProps) => {
  const intl = useIntl();
  const { noiseLevel: noiseLevelParam } = useAutoCutStore(
    state => state.ui.parameters[modeId]
  );
  const [noiseLevel, setNoiseLevel] = useState(noiseLevelParam);

  useEffect(() => {
    setNoiseLevel(noiseLevelParam);
  }, [noiseLevelParam]);

  // const { setIsOpen: setIsTourOpen } = useTour();
  // useEffect(() => {
  //   if (
  //     getLocalStorage(onboardingLocalStorageKeys.ModeSelection) &&
  //     !getLocalStorage(onboardingLocalStorageKeys.Legacy)
  //   ) {
  //     setIsTourOpen(true);
  //   }
  // }, []);

  const debouncedSetNoiseLimit = useCallback(
    debounce(v => {
      setAutocutStore(`ui.parameters.${modeId}.noiseLevel`, Number(v));
    }, 500),
    []
  );

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'silence_step_noise_title',
        defaultMessage: 'Noise level',
      })}
      description={intl.formatMessage({
        id: 'silence_step_noise_helper',
        defaultMessage: 'Adjust noise level',
      })}
    >
      <Slider
        max={0}
        min={-60}
        value={`${noiseLevel} dB`}
        onChange={value => {
          setNoiseLevel(Number(value));
          debouncedSetNoiseLimit(value);
        }}
        variant="valueOnKnob"
        iconLeft={() => <IconVolumeMin size={24} color="gray.500" />}
        iconRight={() => <IconVolumeMax size={24} color="gray.500" />}
      />
    </FormSection>
  );
};
