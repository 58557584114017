export const getDataTourTarget = (identifier: string) => {
  return `[data-tour="${identifier}"]`;
};

export enum EOnboardingTargets {
  INVALID_SELECTION_SCREEN = 'invalid-selection-screen',
  LANGUAGE_SELECTION = 'language-selection',
  PROCESS_MODAL = 'process-modal',
  ERROR_MODAL = 'error-modal',
}
