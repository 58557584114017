import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import { Camera } from '@autocut/types/Camera';
import { Speaker } from '@autocut/types/Speaker';
import logLevel from '@autocut/types/logLevel.enum';
import { updateCameraReducer } from '@autocut/utils/cameras/updateCameraReducer';
import { setAutocutStore } from '@autocut/utils/zustand';
import React, { useMemo } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsCameraVideoFill } from 'react-icons/bs';
import { useIntl } from 'react-intl';
import css from './Camera.module.css';

import { removeCameraReducer } from '@autocut/utils/cameras/removeCameraReducer';

import './camera.css';
import {
  Select,
  SelectOption,
} from '@autocut/designSystem/components/atoms/Select/Select';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import { colors } from '@autocut/designSystem/colors';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { SELECT_COLORS } from '@autocut/components/atoms/CustomSelectText/SelectStyle';
import { SELECT_MIN_WIDTH } from '../../SpeakersStep/Speaker';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

export type CameraProps = {
  camera: Camera;
};

export const CameraComponent = ({ camera }: CameraProps) => {
  const intl = useIntl();
  const { logMessage } = useLogger('CameraComponent');

  const { videoTracks, speakers, cameras } = useAutoCutStore(state => ({
    videoTracks: state.sequence.infos?.videoTracks,
    speakers: state.ui.parameters.podcast.speakers,
    cameras: state.ui.parameters.podcast.cameras,
  }));

  const availableVideoTracks = useMemo(() => {
    const availableTracks = videoTracks
      ?.filter(track => track.nbClips > 0)
      .map((videoTrack: any) => {
        const name = `${intl.formatMessage({
          id: 'text_podcast_track',
          defaultMessage: 'Track',
        })} V${videoTrack.index + 1}`;
        return {
          label: name,
          value: String(videoTrack.index),
        };
      });

    const otherCameraTracks = cameras
      .filter((currentCamera: Camera) => currentCamera.id !== camera.id)
      .map((otherCamera: Camera) => otherCamera.videoTrack);

    const availableVideoTracks = availableTracks?.filter(
      (track: any) =>
        !otherCameraTracks.find(usedTrack => usedTrack === track.value)
    );

    return availableVideoTracks;
  }, [cameras, videoTracks]);

  const availableSpeakers: SelectOption[] = useMemo(
    () =>
      speakers.map((speaker: Speaker, index: number) => {
        const name =
          speaker.name === ''
            ? `${intl.formatMessage({
                id: 'text_podcast_speaker',
                defaultMessage: 'Speaker',
              })} ${index + 1}`
            : speaker.name;

        const color =
          index >= SELECT_COLORS.length ? undefined : SELECT_COLORS[index];

        return {
          label: name,
          value: speaker.id,
          index: index,
          color,
        };
      }),
    [intl, speakers]
  );

  const speakerSelectValues: string[] = useMemo(() => {
    return speakers
      .map(speaker => {
        return speaker.cameras.map((currentCamera: Camera) => {
          if (currentCamera.id === camera.id) {
            return speaker.id;
          }
        });
      })
      .flat(1)
      .filter(Boolean) as string[];
  }, [camera.id, speakers]);

  return (
    <FlexContainer
      flexDirection="row"
      flexWrap="nowrap"
      justifyContent="space-between"
      alignItems="center"
      gap={16}
      style={{
        padding: 8,
      }}
    >
      <FlexContainer
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        gap={12}
      >
        <AiOutlineCloseCircle
          className={`${css.remove} ${css.icon}`}
          onClick={() => {
            logMessage(logLevel.notice, 'Removing camera', {
              [camera.id]: camera,
            });
            setAutocutStore(
              'ui.parameters.podcast.cameras',
              removeCameraReducer(camera.id)
            );
          }}
          size={16}
          color="white"
        />
        <BsCameraVideoFill className={css.icon} size={16} />
        <Select
          filterUsedOptions
          className={camera.videoTrack === '' ? 'default' : ''}
          options={availableVideoTracks || []}
          selected={camera.videoTrack === '' ? undefined : camera.videoTrack}
          onChange={value => {
            logMessage(logLevel.notice, 'Setting camera video track.', {
              [camera.id]: value,
            });
            if (!value) return;

            setAutocutStore(
              'ui.parameters.podcast.cameras',
              updateCameraReducer(camera.id, {
                videoTrack: value,
              })
            );
          }}
          placeholder={
            <Text variant="textSm" color={colors.error500}>
              {`${intl.formatMessage({
                id: 'text_podcast_track',
                defaultMessage: 'Track',
              })} VX`}
            </Text>
          }
          style={{
            minWidth: SELECT_MIN_WIDTH,
          }}
        />
      </FlexContainer>

      <Select
        clearable
        allowMultiple
        allowSelectAll
        options={availableSpeakers}
        selected={speakerSelectValues}
        onChange={value => {
          setAutocutStore(
            'ui.parameters.podcast.cameras',
            updateCameraReducer(camera.id, {
              speakerIds: value,
            })
          );
        }}
        style={{
          flex: 1,
        }}
        placeholder={
          <TranslatedMessage
            id="text_podcast_select_speaker"
            defaultMessage="Select speaker(s)"
          />
        }
      />
    </FlexContainer>
  );
};
