import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useLocale } from '@autocut/hooks/useLocale';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import React from 'react';
import { IoWarningOutline } from 'react-icons/io5';

export const FontNotFoundModal = () => {
  const { locale } = useLocale();

  return (
    <Modal
      title={
        <TranslatedMessage
          id="captions_fontSelect_notFoundFontModal_title"
          defaultMessage="Font not found"
        />
      }
      icon={<IoWarningOutline color="#E25D2A" />}
    >
      <>
        <TranslatedMessage
          id="captions_fontSelect_notFoundFontModal_message"
          defaultMessage="The font was not found on your system, please select a new font to add captions."
        />
        <br />
        <a
          style={{ fontSize: '10px', color: 'inherit' }}
          href="#"
          onClick={() =>
            openLinkInBrowser(
              `https://www.autocut.fr/${locale.toLocaleLowerCase()}/academy/autocut-parameters/captions-fonts/`
            )
          }
        >
          <TranslatedMessage
            id="captions_fontSelect_notFoundFontModal_helper"
            defaultMessage="You can add a new font family by following this tutorial."
          />
        </a>
      </>
    </Modal>
  );
};
