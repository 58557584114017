import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

import React from 'react';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { IoCreateOutline } from 'react-icons/io5';

import css from './CaptionsTranscriptEditorStep.module.scss';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import { colors } from '@autocut/designSystem/colors';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { ECaptionsOnboardingTargets } from '@autocut/context/OnboardingProvider/OnboardingSteps/CaptionsOnboardingSteps';

export const CaptionsTranscriptEditor = () => {
  const { chunks, textDirection } = useAutoCutStore(state => ({
    chunks: state.onGoingProcess.captionChunks,
    textDirection:
      state.ui.parameters.caption.languageOfTranscription.textDirection,
  }));

  if (!chunks) return null;

  const isRtl = textDirection === 'rtl';

  return (
    <FlexContainer
      data-tour={ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPTION_PART}
      flexDirection="column"
      gap={12}
      justifyContent="center"
      alignItems="center"
      style={{
        width: '100%',
      }}
    >
      <FlexContainer
        flexDirection={isRtl ? 'row-reverse' : 'row'}
        gap={8}
        flexWrap="wrap"
        className={css.transcriptContainer}
      >
        {chunks.map((chunk, index) => (
          <Text
            key={index}
            style={{
              display: 'flex',
              flexDirection: isRtl ? 'row-reverse' : 'row',
              alignItems: 'center',
              gap: '2px',
            }}
            variant="textXs"
            color={colors.gray300}
          >
            {chunk.text}
            {Boolean(chunk.emoji) && (
              <img
                src={chunk.emojiUrl}
                alt={chunk.emoji}
                style={{
                  width: '16px',
                  height: '16px',
                }}
              />
            )}
          </Text>
        ))}
      </FlexContainer>

      <Button variant="secondary" fullWidth={false}>
        <FlexContainer gap={4} alignItems="center">
          <IoCreateOutline size="1.2rem" />
          <TranslatedMessage
            id="captions_presetCustomization_editTranscriptionModal_title"
            defaultMessage="Edit transcription"
          />
        </FlexContainer>
      </Button>
    </FlexContainer>
  );
};
