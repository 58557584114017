import React from 'react';
import FlexContainer from '../../molecules/FlexContainer';

import css from './Tabs.module.scss';

export type TabsProps = {
  options: {
    content: React.ReactNode;
    value: string | number;
  }[];
  value: string | number;
  onChange: (value: string | number) => void;
  direction?: 'row' | 'column';
};

export const Tabs = ({
  options,
  value,
  onChange,
  direction = 'row',
}: TabsProps) => {
  return (
    <FlexContainer className={css.tabs} flexDirection={direction}>
      {options.map(({ content, value: optionValue }) => (
        <Tab
          key={optionValue}
          isActive={value === optionValue}
          onClick={() => onChange(optionValue)}
        >
          {content}
        </Tab>
      ))}
    </FlexContainer>
  );
};

type TabProps = {
  children: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
};

const Tab = ({ children, isActive, onClick }: TabProps) => {
  return (
    <div
      className={`${css.tab} ${isActive ? css.active : null}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
