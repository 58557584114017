import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import React from 'react';
import { TranslatedMessage } from '../TranslatedMessage/TranslatedMessage';
import { Authors, EAuthors } from './utils';
import { colors } from '@autocut/designSystem/colors';

type AuthorProps = {
  author: EAuthors;
  size?: number;
  color?: string;
};
export const Author = ({
  author,
  size = 32,
  color = colors.gray100,
}: AuthorProps) => {
  const authorInfo = Authors[author];
  return (
    <FlexContainer flexDirection="row" gap={10} alignItems="center">
      <img
        src={authorInfo.picture}
        alt={authorInfo.name}
        width={size}
        height={size}
        style={{ borderRadius: '50%' }}
      />
      <Text color={color} variant="textMd.bold">
        <TranslatedMessage
          id="onboarding_author"
          defaultMessage="{author} from AutoCut"
          values={{ author: authorInfo.name }}
        />
      </Text>
    </FlexContainer>
  );
};
