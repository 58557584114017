import * as React from 'react';
import { forwardRef, useState } from 'react';

import './button.css';
import styleConfig from '../../styleconfig';
import { CSSProperties } from 'react';
import { LoadingRing } from '@autocut/designSystem/components/atoms/LoadingRing/LoadingRing';

export type ButtonSizes = 'sm' | 'md' | 'lg';
export const isButtonSize = (size: any): size is ButtonSizes =>
  typeof size === 'string' && ['sm', 'md', 'lg'].includes(size);

export type ButtonProps = {
  size?: 'sm' | 'md' | 'lg' | CSSProperties['width'];
  weight?: 'bold' | 'normal';
  compact?: boolean;
  children: any;
  onClickFunction: any;
  id?: string;
  isLoading?: boolean;
  disabled?: boolean;
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'glass';
  percentage?: number;
  className?: string;
  color?: CSSProperties['color'];
  style?: CSSProperties;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size,
      weight = 'normal',
      compact,
      children,
      onClickFunction,
      isLoading: forcedIsLoading,
      disabled,
      id,
      buttonType = 'primary',
      percentage = 0,
      className,
      color,
      style,
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleClick = async () => {
      setIsLoading(true);
      await onClickFunction();
      setIsLoading(false);
    };

    const displayLoader = isLoading || forcedIsLoading;
    return (
      <button
        ref={ref}
        id={id}
        disabled={disabled || displayLoader}
        onClick={handleClick}
        style={
          {
            '--button-progress':
              percentage > 0 && isLoading ? `${percentage.toFixed(1)}` : 0,
            '--button-progress-content':
              percentage > 0 && isLoading ? `'${percentage.toFixed(1)}%'` : '',
            fontFamily: 'Inter, sans-serif',
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? 'not-allowed' : 'pointer',
            border: `1px solid ${
              color
                ? color
                : buttonType === 'primary' || buttonType === 'secondary'
                ? styleConfig.primaryColor
                : buttonType === 'glass'
                ? '#ffffff30'
                : 'none'
            }`,
            backgroundColor:
              buttonType === 'primary'
                ? color
                  ? color
                  : `${styleConfig.primaryColor}`
                : buttonType === 'glass'
                ? '#ffffff0a'
                : 'transparent',
            color:
              buttonType === 'primary' || buttonType === 'glass'
                ? '#ffffff'
                : color
                ? color
                : buttonType === 'secondary'
                ? '#ffffff'
                : styleConfig.primaryColor,
            textDecoration: buttonType === 'tertiary' ? 'underline' : 'none',
            fontWeight:
              buttonType === 'tertiary' || weight === 'bold'
                ? 'bold'
                : 'normal',
            width: compact
              ? 'fit-content'
              : size
              ? isButtonSize(size)
                ? `${styleConfig.buttonSizes[size]}px`
                : size
              : `100%`,
            height: compact
              ? size
                ? isButtonSize(size)
                  ? `${styleConfig.compactButtonHeight[size || 'sm']}px`
                  : undefined
                : undefined
              : undefined,
            padding: compact
              ? '0'
              : size === 'sm'
              ? '5px 10px'
              : size === 'md'
              ? '10px 15px'
              : '8px 16px',
            ...style,
          } as CSSProperties
        }
        className={`${
          buttonType === 'tertiary'
            ? 'button-property-tertiary'
            : 'button-property'
        } ${className}`}
      >
        {percentage > 0 && isLoading ? (
          ' '
        ) : displayLoader ? (
          <LoadingRing />
        ) : (
          children
        )}
      </button>
    );
  }
);
