import { ModalType } from '@autocut/enums/modals.enum';
import { EOnboardingModes, OnboardingTourProps } from './types';
import { CAPTIONS_ONBOARDING_STEPS } from './OnboardingSteps/CaptionsOnboardingSteps';
import { addModalToQueue } from '@autocut/utils/modal/modals.utils';

const DEFAULT_TOUR = {
  showDots: false,
  showNavigation: false,
  showCloseButton: false,
  showPrevNextButtons: false,
  showBadge: false,
};

export const ONBOARDING_TOURS: Record<EOnboardingModes, OnboardingTourProps> = {
  [EOnboardingModes.WELCOME]: {
    openTour: () => addModalToQueue(ModalType.OnboardingWelcome),
  },
  [EOnboardingModes.CAPTIONS]: {
    openTour: () => addModalToQueue(ModalType.OnboardingCaptions),
    steps: CAPTIONS_ONBOARDING_STEPS,
    ...DEFAULT_TOUR,
  },
};
