import React from 'react';

import { IconComponent, IconComponentProps } from '../Icon';

export const IconPlusCircle = (props: IconComponentProps) => {
  return (
    <IconComponent {...props} viewBox="0 0 24 24" fill="none">
      <path
        stroke="currentColor"
        d="M12 8v8m-4-4h8m6 0a10 10 0 1 1-20 0 10 10 0 0 1 20 0Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconComponent>
  );
};
