import React from 'react';
import FlexContainer, {
  FlexContainerProps,
} from '../../molecules/FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';

import css from './Alert.module.scss';
import { Text } from '../Text/Text';

export type AlertProps = {
  children: React.ReactNode;
  icon?: React.ReactNode;
} & FlexContainerProps;

export const Alert = ({ children, icon = undefined }: AlertProps) => {
  return (
    <FlexContainer className={css.root} gap={Spacing.s2} alignItems="center">
      {icon && icon}
      <Text variant="textSm.medium" color="gray.300">
        {children}
      </Text>
    </FlexContainer>
  );
};
