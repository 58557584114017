import * as React from 'react';
import { useMemo, useState } from 'react';

import { AiOutlineCloseCircle } from 'react-icons/ai';

import { SELECT_COLORS } from '@autocut/components/atoms/CustomSelectText/SelectStyle';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import { Speaker } from '@autocut/types/Speaker';
import logLevel from '@autocut/types/logLevel.enum';
import { debounce } from '@autocut/utils/debounce';
import { removeSpeakerReducer } from '@autocut/utils/speakers/removeSpeakerReducer';
import { updateSpeakerReducer } from '@autocut/utils/speakers/updateSpeakerReducer';
import { setAutocutStore } from '@autocut/utils/zustand';
import { useIntl } from 'react-intl';

import { Select } from '@autocut/designSystem/components/atoms/Select/Select';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Input } from '@autocut/designSystem/components/atoms/Input/Input';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import { colors } from '@autocut/designSystem/colors';
import { HiOutlineMicrophone } from 'react-icons/hi';

export type SpeakerProps = {
  speaker: Speaker;
  index: number;
};

export const SELECT_MIN_WIDTH = 120;

export const SpeakerComponent = ({
  speaker: currentSpeaker,
  index: indexSpeaker,
}: SpeakerProps) => {
  const intl = useIntl();
  const { logMessage } = useLogger('SpeakerComponent');
  const { audioTracks } = useAutoCutStore(state => ({
    audioTracks: state.sequence.infos?.audioTracks,
  }));

  const availableAudioTracks = useMemo(() => {
    const availableTracks = audioTracks
      ?.filter(audioTrack => audioTrack.nbClips > 0)
      .map((audioTrack: any) => {
        const name = `${intl.formatMessage({
          id: 'text_podcast_track',
          defaultMessage: 'Track',
        })} A${audioTrack.index + 1}`;
        return {
          label: name,
          valueInDropdown: name,
          value: String(audioTrack.index),
        };
      });

    return availableTracks;
  }, [audioTracks, intl]);

  const [name, setName] = useState(currentSpeaker.name);

  const handleNameChange = (value: string) => {
    setName(value);

    void debounce(() => {
      logMessage(logLevel.notice, 'Changing speaker name', {
        [currentSpeaker.id]: value,
      });
      setAutocutStore(
        'ui.parameters.podcast.speakers',
        updateSpeakerReducer(currentSpeaker.id, { name: value })
      );
    }, 500)();
  };

  return (
    <FlexContainer
      justifyContent="space-between"
      alignItems="center"
      style={{
        padding: 8,
      }}
      gap={24}
    >
      <FlexContainer
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        gap={8}
        flexGrow={true}
      >
        <AiOutlineCloseCircle
          onClick={() => {
            logMessage(logLevel.notice, 'Removing speaker', {
              [currentSpeaker.id]: currentSpeaker,
            });
            setAutocutStore(
              'ui.parameters.podcast.speakers',
              removeSpeakerReducer(currentSpeaker.id)
            );
          }}
          size={16}
          color="white"
          style={{ cursor: 'pointer' }}
        />
        <HiOutlineMicrophone size={24} />

        <Select
          options={availableAudioTracks || []}
          selected={
            currentSpeaker.audioTrack === ''
              ? undefined
              : currentSpeaker.audioTrack
          }
          onChange={value => {
            logMessage(logLevel.notice, 'Setting speaker audio track.', {
              [currentSpeaker.id]: value,
            });

            if (!value) return;

            setAutocutStore(
              'ui.parameters.podcast.speakers',
              updateSpeakerReducer(currentSpeaker.id, {
                audioTrack: value,
              })
            );
          }}
          placeholder={
            <Text variant="textSm" color={colors.error500}>
              {`${intl.formatMessage({
                id: 'text_podcast_track',
                defaultMessage: 'Track',
              })} AX`}
            </Text>
          }
          style={{
            minWidth: SELECT_MIN_WIDTH,
          }}
        />

        <FlexContainer
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
          flexGrow={true}
          gap={4}
        >
          <div
            style={{
              width: 16,
              height: 16,
              borderRadius: '50%',
              backgroundColor:
                SELECT_COLORS[
                  indexSpeaker >= SELECT_COLORS.length
                    ? SELECT_COLORS.length - 1
                    : indexSpeaker
                ],
            }}
          />
          <Input
            value={name}
            placeholder={intl.formatMessage({
              id: 'input_speaker_name_placeholder',
              defaultMessage: 'Name(s) of the speaker(s)',
            })}
            onChange={handleNameChange}
            style={{
              flex: 1,
              border: 'none',
            }}
          />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
