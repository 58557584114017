import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import { RadioForm } from '@autocut/designSystem/components/molecules/RadioForm/RadioForm';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';

const RADIO_FORM_OPTIONS = (currentOption: 'remove' | 'disable') => [
  {
    name: 'remove',
    title: <TranslatedMessage id="delete" defaultMessage="Remove" />,
    value: true,
    defaultChecked: currentOption === 'remove',
  },
  {
    name: 'disable',
    title: <TranslatedMessage id="disable" defaultMessage="Disable" />,
    value: false,
    defaultChecked: currentOption === 'disable',
  },
];

export const PodcastSilenceStep = () => {
  const podcastParameters = useAutoCutStore(
    state => state.ui.parameters.podcast
  );

  return (
    <FormSection
      title={
        <TranslatedMessage
          id="title_podcast_silences_step"
          defaultMessage="Remove or disable"
        />
      }
      description={
        <TranslatedMessage
          id="description_podcast_silences_step"
          defaultMessage="Choose whether you want to disable or to delete the clips that are not shown on the screen."
        />
      }
    >
      <PaddedSection>
        <RadioForm
          onChange={value =>
            setAutocutStore('ui.parameters.podcast.deleteUnusedClips', value)
          }
          options={RADIO_FORM_OPTIONS(
            podcastParameters.deleteUnusedClips ? 'remove' : 'disable'
          )}
          flexDirection="row"
          gap={24}
        />
      </PaddedSection>
    </FormSection>
  );
};
