import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { convertSecondsToHMSString } from '@autocut/utils/date.utils';
import React, { useMemo, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import css from './TrackSelectionDisplay.module.css';
import { ClipTypeEnum } from '../../../../jsx/ppro/types.ppro';
import { RadioButton } from '../RadioButton/RadioButton';
import { setAutocutStore } from '@autocut/utils/zustand';
import { CheckBox } from '@autocut/designSystem/components/atoms/CheckBox/CheckBox';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';

export type TrackSelectionDisplayProps = {
  track: Track;
  isDeactivable?: boolean;
};

const TrackSelectionDisplay = ({
  track,
  isDeactivable = false,
}: TrackSelectionDisplayProps) => {
  const [open, setOpen] = useState(false);

  const isChecked = useMemo(() => {
    if (!isDeactivable) return false;

    return track.isUsed === undefined ? true : track.isUsed;
  }, [track.isUsed]);

  return (
    <FlexContainer justifyContent="space-between" alignItems="flex-start">
      <FlexContainer
        className={css.leftContainer}
        alignItems="center"
        gap={Spacing.s2}
      >
        {isDeactivable && (
          <CheckBox
            size={20}
            checked={track.isUsed === undefined ? true : track.isUsed}
            onChange={e => {
              setAutocutStore('sequence.infos', state => {
                return {
                  ...(state.sequence.infos || {}),
                  audioTracks: state.sequence.infos?.audioTracks.map(
                    currentTrack =>
                      currentTrack.index === track.index
                        ? { ...currentTrack, isUsed: e }
                        : currentTrack
                  ),
                } as Sequence;
              });
            }}
          />
        )}
        <Text
          variant="textXs.medium"
          color="gray.300"
          className={`${css.trackName} ${!isDeactivable ? css.margin : ''}`}
        >{`${track.type === ClipTypeEnum.Video ? 'V' : 'A'}${
          track.index + 1
        }`}</Text>
      </FlexContainer>

      <FlexContainer
        flexDirection="column"
        gap={Spacing.s1}
        className={css.rightContainer}
      >
        <FlexContainer
          flexDirection="row"
          justifyContent="flex-end"
          onClick={() => setOpen(c => !c)}
          className={css.title}
        >
          <Text
            className={css.monospace}
            variant="textXs.medium"
            color="gray.300"
          >
            <TranslatedMessage
              id="captions_script_sequence_track_clip_unit"
              defaultMessage="{nbClips} clips | {from} → {to}"
              values={{
                nbClips: track.nbClipsSelected,
                from: convertSecondsToHMSString(track.start),
                to: convertSecondsToHMSString(track.end),
              }}
            />
          </Text>
          <BiChevronDown
            className={css.chevron}
            size={20}
            style={{
              transform: `rotateZ(${open ? 180 : 0}deg)`,
            }}
          />
        </FlexContainer>
        <FlexContainer
          className={css.clips}
          flexDirection="column"
          gap={Spacing.s1}
        >
          {open
            ? track.selectedClips.map(clip => (
                <Text
                  key={clip.id}
                  className={css.monospace}
                  variant="textXs.medium"
                  color="gray.300"
                >
                  <TranslatedMessage
                    id="captions_script_sequence_track_clip_unit_detail"
                    defaultMessage="{clipName} | {from} → {to}"
                    values={{
                      clipName: clip.path.split(/[\/\\]/).pop(),
                      from: convertSecondsToHMSString(clip.start),
                      to: convertSecondsToHMSString(clip.end),
                    }}
                  />
                </Text>
              ))
            : null}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default TrackSelectionDisplay;
