import React, { CSSProperties, useState } from 'react';
import { CheckBoxCommonProps, CheckBoxProps } from '../../CheckBox';

import css from './Box.module.scss';
import { IconCheck } from '../../../Icon/general/IconCheck';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { InputLabel } from '@autocut/designSystem/components/molecules/InputLabel/InputLabel';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';

export type BoxProps = {} & InputLabel.Props;

export const Box = ({
  size,
  title,
  description,
  onChange,
  checked,
}: BoxProps & CheckBoxCommonProps) => {
  const [id, _] = useState(Math.random().toString(36).substring(7));

  return (
    <FlexContainer gap={Spacing.s1} alignItems="center">
      <div
        style={{ '--size': `${size}px` } as CSSProperties}
        className={css.container}
      >
        <input
          className={css.root}
          id={id}
          type="checkbox"
          checked={checked}
          onChange={() => onChange && onChange(!checked)}
        />
        <label className={css.label} htmlFor={id} />
        <IconCheck className={css.icon} size={size * 0.7} color="white" />
      </div>
      {(title || description) && (
        <InputLabel title={title} description={description} />
      )}
    </FlexContainer>
  );
};
