import React from 'react';
import css from './PaddedSection.module.css';
import FlexContainer, {
  FlexContainerProps,
} from '../../molecules/FlexContainer';
import { colors } from '@autocut/designSystem/colors';

export type PaddedSectionType = {
  color?: string;
  children: React.ReactNode;
} & Partial<FlexContainerProps>;

export const PaddedSection = ({
  children,
  color = colors.gray400,
  ...props
}: PaddedSectionType) => {
  return (
    <FlexContainer
      style={{
        borderColor: color,
      }}
      className={css.root}
      flexDirection="column"
      {...props}
    >
      {children}
    </FlexContainer>
  );
};
