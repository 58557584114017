import { AutoCutState, setAutocutStore } from '@autocut/utils/zustand';
import { Camera } from '@autocut/types/Camera';
import { updateSpeakerReducer } from '../speakers/updateSpeakerReducer';
import { Speaker } from '@autocut/types/Speaker';

export const updateCameraReducer =
  (cameraId: string, updatedFields: Partial<Camera>) =>
  (state: AutoCutState) => {
    return state.ui.parameters.podcast.cameras.map((camera: Camera) => {
      if (camera.id === cameraId) {
        const updatedCamera: Camera = {
          ...camera,
          ...updatedFields,
        };

        const newCameraSpeakerIds = updatedCamera.speakerIds;

        const speakers = state.ui.parameters.podcast.speakers;

        for (const speaker of speakers) {
          if (speakerHasCamera(speaker, cameraId)) {
            //If the speaker has the camera and the speaker is not in the newCameraSpeakerIds, remove it
            if (!newCameraSpeakerIds.includes(speaker.id)) {
              setAutocutStore(
                'ui.parameters.podcast.speakers',
                updateSpeakerReducer(speaker.id, {
                  cameras: speaker.cameras.filter(
                    camera => camera.id !== cameraId
                  ),
                })
              );
            } else {
              //If the speaker has the camera and the speaker is in the newCameraSpeakerIds, update it
              setAutocutStore(
                'ui.parameters.podcast.speakers',
                updateSpeakerReducer(speaker.id, {
                  cameras: speaker.cameras.map(speakerCamera => {
                    if (speakerCamera.id === cameraId) {
                      return updatedCamera;
                    }
                    return speakerCamera;
                  }),
                })
              );
            }
          } else {
            //If the speaker has not the camera and the speaker is in the newCameraSpeakerIds, add it
            if (newCameraSpeakerIds.includes(speaker.id)) {
              setAutocutStore(
                'ui.parameters.podcast.speakers',
                updateSpeakerReducer(speaker.id, {
                  cameras: [...speaker.cameras, updatedCamera],
                })
              );
            }
          }
        }

        return updatedCamera;
      }
      return camera;
    });
  };

const speakerHasCamera = (speaker: Speaker, cameraId: string) => {
  return speaker.cameras.some(camera => camera.id === cameraId);
};
