import * as React from 'react';

import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { CameraTimeStep } from '@autocut/components/steps/CamTimeStep';
import { CamerasStep } from '@autocut/components/steps/CamerasStep/CamerasStep';
import { PodcastSilenceStep } from '@autocut/components/steps/PodcastSilenceStep/PodcastSilenceStep';
import { SpeakersStep } from '@autocut/components/steps/SpeakersStep';
import { AutocutModes } from '@autocut/enums/modes.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import { handleTrialTask } from '@autocut/utils/game/trialGamfication.util';
import { handlePodcastProcess } from '@autocut/utils/podcast/handlePodcastProcess';
import { sequenceHasEnoughClipsForPodcast } from '@autocut/utils/podcast/hasEnoughClips';
import { setAutocutStore } from '@autocut/utils/zustand';
import { useEffect, useMemo } from 'react';
import { BiMicrophone } from 'react-icons/bi';
import { useIntl } from 'react-intl';
import { ModeWrapper } from '../ModeWrapper';

import podcastSelectionImageUrl from '@autocut/assets/images/selectionScreen/podcast.png';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { colors } from '@autocut/designSystem/colors';
const podcastSelectionImage = new URL(podcastSelectionImageUrl, import.meta.url)
  .href;

export const PodcastMode = () => {
  const intl = useIntl();
  const { sequenceInfos, deleteUnusedClips } = useAutoCutStore(state => ({
    sequenceInfos: state.sequence.infos,
    deleteUnusedClips: state.ui.parameters.podcast.deleteUnusedClips,
  }));
  useLogger('PodcastMode');

  const hasEnoughClips = useMemo(sequenceHasEnoughClipsForPodcast, [
    sequenceInfos,
  ]);

  // We don't want to process unused tracks if the user change sequences
  useEffect(() => {
    if (sequenceInfos && hasEnoughClips) {
      const availableAudioTracks = sequenceInfos.audioTracks?.filter(
        track => track.nbClips > 0
      );
      const defaultSpeakers = AutocutModes.Podcast.defaultParameters.speakers;
      defaultSpeakers[0].audioTrack = `${availableAudioTracks[0].index}`;
      defaultSpeakers[1].audioTrack = `${availableAudioTracks[1].index}`;

      const availableVideoTracks = sequenceInfos.videoTracks?.filter(
        track => track.nbClips > 0
      );
      const defaultCameras = AutocutModes.Podcast.defaultParameters.cameras;
      defaultCameras[0].videoTrack = `${availableVideoTracks[0].index}`;
      defaultCameras[1].videoTrack = `${availableVideoTracks[1].index}`;

      setAutocutStore('ui.parameters.podcast.cameras', defaultCameras);
      setAutocutStore('ui.parameters.podcast.speakers', defaultSpeakers);
    } else {
      setAutocutStore(
        'ui.parameters.podcast.cameras',
        AutocutModes.Podcast.defaultParameters.cameras
      );
      setAutocutStore(
        'ui.parameters.podcast.speakers',
        AutocutModes.Podcast.defaultParameters.speakers
      );
    }
  }, [hasEnoughClips, sequenceInfos]);

  return (
    <ModeWrapper
      Icon={BiMicrophone}
      text={'Auto Podcast'}
      displayCondition={hasEnoughClips}
      invalidSelectionScreen={{
        titleId: 'podcast_not_available_title',
        titleDefaultMessage:
          'Select a sequence with at least 2 video and audio tracks',
        texts: [
          {
            id: 'podcast_not_available',
            defaultMessage:
              'AutoCut Podcast is not available for your sequence',
          },
        ],
        linkId: 'podcast_link_tuto',
        selectionImage: podcastSelectionImage,
      }}
    >
      <FlexContainer flexDirection="column" gap={Spacing.s4}>
        <SpeakersStep />
        <CamerasStep />
        <CameraTimeStep />
        <PodcastSilenceStep />
      </FlexContainer>
      <ModeFooter
        renderButton={({ buttonRef, isLoading, isDisabled }) => (
          <Button
            ref={buttonRef}
            disabled={isDisabled || isLoading}
            onClick={async () => {
              await handlePodcastProcess(intl);
              await handleTrialTask('podcast');
            }}
            color={colors.primary600}
          >
            <TranslatedMessage
              id={`podcast_button_${deleteUnusedClips ? 'delete' : 'disable'}`}
              defaultMessage="Podcast"
            />
          </Button>
        )}
      />
    </ModeWrapper>
  );
};
