import React, { useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { debounce } from '@autocut/utils/debounce';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import { Input } from '@autocut/designSystem/components/atoms/Input/Input';

export type PaddingStepProps = {
  modeId?: 'silence';
};

export const PaddingStep = ({ modeId = 'silence' }: PaddingStepProps) => {
  const intl = useIntl();
  const { marginBefore: marginBeforeParam, marginAfter: marginAfterParam } =
    useAutoCutStore(state => state.ui.parameters[modeId]);

  const [marginBefore, setMarginBefore] = useState(marginBeforeParam);
  const [marginAfter, setMarginAfter] = useState(marginAfterParam);

  useEffect(() => {
    setMarginBefore(marginBeforeParam);
  }, [marginBeforeParam]);

  useEffect(() => {
    setMarginAfter(marginAfterParam);
  }, [marginAfterParam]);

  const debouncedSetMarginBefore = useCallback(
    debounce((value: number) => {
      if (value !== autocutStoreVanilla().ui.parameters[modeId].marginBefore)
        setAutocutStore(`ui.parameters.${modeId}.marginBefore`, value);
    }, 1000),
    []
  );

  const debouncedSetMarginAfter = useCallback(
    debounce((value: number) => {
      if (value !== autocutStoreVanilla().ui.parameters[modeId].marginAfter)
        setAutocutStore(`ui.parameters.${modeId}.marginAfter`, value);
    }, 1000),
    []
  );

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'silence_step_padding_title',
        defaultMessage: 'Padding',
      })}
    >
      <PaddedSection>
        <FlexContainer flexDirection="column" gap={Spacing.s2}>
          <FormSection
            variant="secondary"
            title={intl.formatMessage({
              id: 'silence_step_padding_before_title',
              defaultMessage: 'Before padding',
            })}
            description={intl.formatMessage({
              id: 'silence_step_padding_before_helper',
              defaultMessage:
                'Silent time before a noise - to avoid sudden resumption of speech',
            })}
          >
            <Input
              type="number"
              style={{ width: '50%' }}
              value={`${marginBefore * 1000}`}
              onChange={async (value: string) => {
                const numberValue = Number(value) / 1000;
                setMarginBefore(numberValue);
                await debouncedSetMarginBefore(numberValue);
              }}
              unit={intl.formatMessage({
                id: 'millisecondUnit',
                defaultMessage: 'milliseconds',
              })}
            />
          </FormSection>
          <FormSection
            variant="secondary"
            title={intl.formatMessage({
              id: 'silence_step_padding_after_title',
              defaultMessage: 'After padding',
            })}
            description={intl.formatMessage({
              id: 'silence_step_padding_after_helper',
              defaultMessage:
                'Silent time after a noise - to avoid harsh speech interruptions',
            })}
          >
            <Input
              type="number"
              style={{ width: '50%' }}
              value={`${marginAfter * 1000}`}
              onChange={async (value: string) => {
                const numberValue = Number(value) / 1000;
                setMarginAfter(numberValue);
                await debouncedSetMarginAfter(numberValue);
              }}
              unit={intl.formatMessage({
                id: 'millisecondUnit',
                defaultMessage: 'milliseconds',
              })}
            />
          </FormSection>
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};
