import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { colors } from '@autocut/designSystem/colors';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import React from 'react';
import { setAutocutStore } from '@autocut/utils/zustand';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { EAuthors } from '@autocut/components/atoms/Author/utils';
import {
  useCompleteOnboarding,
  useLaunchTour,
} from '@autocut/context/OnboardingProvider/OnboardingProvider';

import { AuthoredModal } from '@autocut/components/partials/AuthoredModal/AuthoredModal';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';

export const OnboardingCaptionsModal = () => {
  const launchTour = useLaunchTour();
  const completeOnboarding = useCompleteOnboarding();

  return (
    <AuthoredModal
      variant="outline"
      author={EAuthors.ARTHUR}
      description={
        <Text color={colors.gray200}>
          <TranslatedMessage
            id="onboarding_captions_description"
            defaultMessage="Hey, welcome to AutoCaptions! This mode will be your go to to enhance your viewers experience through captioning, whether you create vertical short content or long videos.{br}{br}In 5 minutes, you will be able to use the full potential of this feature."
          />
        </Text>
      }
      body={
        <FlexContainer flexDirection="column" gap={12} alignItems="center">
          <Button
            onClick={() => {
              setAutocutStore('ui.openedModalName', '');

              launchTour();
            }}
            color={colors.primary600}
            fullWidth={false}
          >
            <Text variant="textSm.medium" color="white">
              <TranslatedMessage
                id="onboarding_start_tutorial"
                defaultMessage="Start the tutorial"
              />
            </Text>
          </Button>
          <Button
            color={colors.gray600}
            variant="tertiary"
            onClick={async () => {
              await completeOnboarding(false);
              setAutocutStore('ui.openedModalName', '');
            }}
            style={{ textDecoration: 'none' }}
            fullWidth={false}
          >
            <Text variant="textSm.regular" color="white">
              <TranslatedMessage id="onboarding_skip" defaultMessage="Skip" />
            </Text>
          </Button>
        </FlexContainer>
      }
      opened={true}
      title={
        <TranslatedMessage
          id="onboarding_onboarding_title"
          defaultMessage="Welcome to AutoCut 👋"
        />
      }
      closeOnClickOutside={false}
      withCloseButton={false}
      fullWidth
    />
  );
};
