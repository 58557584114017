import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { StepperModal } from '@autocut/components/partials/StepperModal/StepperModal';
import { colors } from '@autocut/designSystem/colors';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import React from 'react';
import { OnboardingDiscoverForm } from './OnboardingDiscoverForm/OnboardingDiscoverForm';
import { autocutApi } from '@autocut/utils';
import { AutoCutApiError } from '@autocut/utils/errors/AutoCutApiError';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { setAutocutStore } from '@autocut/utils/zustand';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import { isDiscoverFormValid } from './OnboardingDiscoverForm/utils';
import { ModalType } from '@autocut/enums/modals.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { handleTrialTask } from '@autocut/utils/game/trialGamfication.util';
import { EAuthors } from '@autocut/components/atoms/Author/utils';
import { useCompleteOnboarding } from '@autocut/context/OnboardingProvider/OnboardingProvider';
import { addModalToQueue } from '@autocut/utils/modal/modals.utils';
import { DISCORD_LINK } from '@autocut/components/atoms/DiscordCta/DiscordCta';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { AUTOCUT_CONSTANTS } from '@autocut/constants/config';
import { CURRENT_ENV } from '@autocut/utils/currentEnv.utils';
import { useIntl } from 'react-intl';

import css from './OnboardingWelcomeModal.module.scss';
import { closeModal } from '@autocut/utils/modal/closeModal';

export const OnboardingWelcomeModal = () => {
  const intl = useIntl();

  const [discoverForm, setDiscoverForm] = React.useState<{
    discover: string | null;
    jobStatus: string | null;
    typeOfCreation: string[] | null;
  }>({
    discover: null,
    jobStatus: null,
    typeOfCreation: null,
  });
  const [showError, setShowError] = React.useState(false);

  const completeOnboarding = useCompleteOnboarding();

  const [license] = useAutoCutStore(state => [state.user.license]);

  const isTrial = license?.type === 'trial';

  const customCloseModal = async () => {
    await autocutApi
      .post('/statistics/onboarding-stats', discoverForm)
      .catch((error: AutoCutApiError | IncrementalError) => {
        return {} as never;
      });

    setAutocutStore('user.additionalInformations', s => ({
      ...s.user.additionalInformations,
      displayOnboarding: false,
    }));

    await completeOnboarding(true);
    closeModal();
  };

  const ONBOARDING_STEPS = [
    {
      label: (
        <TranslatedMessage
          id="onboarding_onboarding_step1_title"
          defaultMessage="Welcome message"
        />
      ),
      author: EAuthors.ADRIAN,
      description: (
        <TranslatedMessage
          id="onboarding_onboarding_step1_description"
          defaultMessage="Welcome to AutoCut! 👋👋{br}{br}We're excited to improve your editing workflow! Enjoy your unlimited free trial and explore all the cutting-edge features AutoCut has to offer.{br}{br}Connect with us on Discord for tips, feature announcements, and the latest AutoCut news. Join our community here: <discordLink>Join discord</discordLink>.{br}{br}For comprehensive guides and tutorials, visit the AutoCut Academy: <academyLink>Visit Academy</academyLink>."
          values={{
            discordLink: chunk => (
              <span
                className={css.link}
                onClick={() => openLinkInBrowser(DISCORD_LINK)}
              >
                {chunk}
              </span>
            ),
            academyLink: chunk => (
              <span
                className={css.link}
                onClick={() =>
                  openLinkInBrowser(
                    AUTOCUT_CONSTANTS[CURRENT_ENV].ACADEMY_ADDRESS[
                      intl.locale.toUpperCase() as 'FR' | 'EN'
                    ]
                  )
                }
              >
                {chunk}
              </span>
            ),
          }}
        />
      ),
    },
    {
      label: (
        <TranslatedMessage
          id="onboarding_onboarding_step2_title"
          defaultMessage="How do you edit videos?"
        />
      ),
      author: EAuthors.ADRIAN,
      description: (
        <TranslatedMessage
          id="onboarding_onboarding_step2_description"
          defaultMessage="To better enhance our extension and innovate new features, we need your valuable insights. Help us understand your video editing process by answering a few quick questions:"
        />
      ),
      body: (
        <OnboardingDiscoverForm
          onChange={(discover, jobStatus, typeOfCreation) => {
            setShowError(false);
            setDiscoverForm({ discover, jobStatus, typeOfCreation });
          }}
        />
      ),
      cta: (nextStep: () => void) => (
        <FlexContainer
          style={{
            width: '100%',
          }}
          flexDirection="column"
          gap={4}
        >
          {showError && (
            <Text color={colors.error500} variant="textSm.medium">
              <TranslatedMessage
                id="onboarding_onboarding_discoverForm_error"
                defaultMessage="The form is mandatory as it helps us develop the best possible product."
              />
            </Text>
          )}

          <Button
            onClick={() => (isFormValid ? nextStep() : setShowError(true))}
            color={colors.primary600}
          >
            {isTrial ? (
              <TranslatedMessage id="onboarding_next" defaultMessage="Next" />
            ) : (
              <TranslatedMessage id="onboarding_close" defaultMessage="Close" />
            )}
          </Button>
        </FlexContainer>
      ),
    },
  ];

  const TRIAL_STEP = {
    label: (
      <TranslatedMessage
        id="onboarding_onboarding_step3_title"
        defaultMessage="A little gift 🎁"
      />
    ),
    author: EAuthors.ADRIAN,
    description: (
      <TranslatedMessage
        id="onboarding_onboarding_step3_description"
        defaultMessage="You are now ready to start your journey on integrating AI in your editing workflow. You might feel overwhelmed but don’t worry, we’ll be there along the way to help you unleash the full potential of it.{br}{br}But before you explore all the AutoCut features, I have a special gift for you 🤫"
      />
    ),
    cta: (nextStep: () => void) => (
      <Button
        onClick={async () => {
          await handleTrialTask('onboarding');

          nextStep();
          addModalToQueue(ModalType.GamingTrial);
        }}
        color={colors.primary600}
      >
        <TranslatedMessage
          id="onboarding_get_gift"
          defaultMessage="Get my gift 🎁"
        />
      </Button>
    ),
  };

  const STEPS = isTrial ? [...ONBOARDING_STEPS, TRIAL_STEP] : ONBOARDING_STEPS;

  const isFormValid = isDiscoverFormValid(discoverForm);

  return (
    <StepperModal
      steps={STEPS}
      opened={true}
      title={
        <TranslatedMessage
          id="onboarding_onboarding_title"
          defaultMessage="Welcome to AutoCut 👋"
        />
      }
      closeOnClickOutside={false}
      withCloseButton={false}
      onClose={customCloseModal}
      fullWidth
      variant="outline"
    />
  );
};
