import React, { CSSProperties, useEffect, useState } from 'react';
import FlexContainer, { FlexContainerProps } from '../FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import { RadioButton } from '../../atoms/RadioButton/RadioButton';

export type RadioFormProps<T> = {
  options: RadioButton.Props<T>[];
  onChange: (value: T) => void;
} & Omit<FlexContainerProps, 'onChange'>;

export const RadioForm = <T,>({
  options,
  onChange,
  ...props
}: RadioFormProps<T>) => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  );

  return (
    <FlexContainer flexDirection="column" gap={Spacing.s1} {...props}>
      {options.map((option, index) => (
        <RadioButton
          key={index}
          checked={
            selectedIndex === index ||
            (selectedIndex === undefined && option.defaultChecked)
          }
          onClick={() => {
            if (option.disabled) return;

            onChange(option.value);
            setSelectedIndex(index);
          }}
          {...option}
        />
      ))}
    </FlexContainer>
  );
};
