import { PreviewFrame } from '@autocut/components/atoms/PreviewFrame/PreviewFrame';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { autocutStoreVanilla } from '@autocut/utils/zustand';
import React, { useMemo } from 'react';

const CaptionsPreview = ({
  withPreviewButton,
  maxHeight = '200px',
  disableInteractions = false,
}: {
  withPreviewButton?: boolean;
  maxHeight?: `${number}px`;
  disableInteractions?: boolean;
}) => {
  const { lastSequenceSettings, minVideoTime, maxVideoTime } = useAutoCutStore(
    state => ({
      lastSequenceSettings: state.sequence.lastSettings,
      minVideoTime: state.sequence.infos?.videoTracks
        ?.filter(track => track.nbClipsSelected > 0)
        ?.reduce((res, track) => Math.min(res, track.start), Infinity),
      maxVideoTime: state.sequence.infos?.videoTracks?.reduce(
        (res, track) => Math.max(res, track.end),
        0
      ),
    })
  );

  const initialPosition = useMemo(() => {
    if (!lastSequenceSettings) return undefined;

    const autocutState = autocutStoreVanilla();
    const { xPercentage, yPercentage } =
      autocutState.ui.parameters.caption.position;
    const { width: seqWidth, height: seqHeight } = lastSequenceSettings;

    return { x: xPercentage * seqWidth, y: yPercentage * seqHeight };
  }, [lastSequenceSettings]);

  return (
    <FlexContainer
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{
        alignSelf: 'center',
        height: '100%',

        pointerEvents: disableInteractions ? 'none' : 'auto',
        userSelect: disableInteractions ? 'none' : 'auto',
      }}
    >
      <PreviewFrame
        interfaces={[
          {
            type: 'captions',
          },
        ]}
        initialPosition={initialPosition}
        anchors={false}
        anchorsDisplay={false}
        cursorBounds={
          (minVideoTime || Infinity) < 10000000 && (maxVideoTime || 0) > 0
            ? ([minVideoTime, maxVideoTime] as [number, number])
            : undefined
        }
        maxHeight={maxHeight}
        withPreviewButton={withPreviewButton}
      />
    </FlexContainer>
  );
};

export default CaptionsPreview;
