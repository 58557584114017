import { CustomSelectText } from '@autocut/components/atoms/CustomSelectText/CustomSelectText';
import { EOnboardingTargets } from '@autocut/context/OnboardingProvider/OnboardingSteps/utils';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import { StepLayout } from '@autocut/layouts/StepLayout';
import logLevel from '@autocut/types/logLevel.enum';
import { NeedingTranscriptionModes } from '@autocut/types/transcription';
import { getLanguages } from '@autocut/utils/languages.utils';
import { isProviderAvailable } from '@autocut/utils/transcript/isProviderAvailable/isProviderAvailable';
import { setAutocutStore } from '@autocut/utils/zustand';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

export type TranscriptLanguageStepProps = {
  modeId?: NeedingTranscriptionModes;
};

export const TranscriptLanguageStep = ({
  modeId = 'caption',
}: TranscriptLanguageStepProps) => {
  const { logMessage } = useLogger('TranscriptLanguageStep');
  const intl = useIntl();

  const languageValues = React.useMemo(() => getLanguages(intl), [intl]);

  const { languageOfTranscription, modeParameters } = useAutoCutStore(
    state => ({
      languageOfTranscription:
        state.ui.parameters[modeId].languageOfTranscription,
      modeParameters: state.ui.parameters[modeId],
    })
  );

  useEffect(() => {
    if (languageOfTranscription) {
      const foundLanguage = languageValues.find(
        language => language.value === languageOfTranscription.value
      );

      if (foundLanguage) {
        setAutocutStore(
          `ui.parameters.${modeId}.languageOfTranscription`,
          foundLanguage as any
        );
      }
    }
  }, []);

  useEffect(() => {
    setAutocutStore(
      'ui.currentTranscription.isProviderAvailable',
      isProviderAvailable(modeId)
    );
  }, [languageOfTranscription, modeParameters, modeId]);

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'language_selection_step_title_helper',
        defaultMessage: 'Select audio clip language',
      })}
      alwaysOpen
    >
      <FlexContainer flexDirection="column" gap={8}>
        <div data-tour={EOnboardingTargets.LANGUAGE_SELECTION}>
          <CustomSelectText
            isSearchable
            options={languageValues}
            handleSelectChange={option => {
              logMessage(
                logLevel.notice,
                `audio language changed to ${option?.value}`
              );
              if (option) {
                setAutocutStore(
                  `ui.parameters.${modeId}.languageOfTranscription`,
                  option
                );
              }
            }}
            initialValue={languageOfTranscription}
          />
        </div>
      </FlexContainer>
    </StepLayout>
  );
};
