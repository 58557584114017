import React, { ChangeEvent } from 'react';
import { InputVariant } from './variants';

import css from './Input.module.scss';
import { Text } from '../Text/Text';
import { colors } from '@autocut/designSystem/colors';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import { FormSection } from '../../layout/FormSection/FormSection';
import ConditionalWrap from '@autocut/utils/conditional-wrapping';

export namespace Input {
  export type Props = {
    variant?: InputVariant;
    className?: string;
    style?: React.CSSProperties;
    unit?: React.ReactNode;
    onChange: (value: string) => void;
    label?: {
      text: FormSection.Props['title'];
      helper: FormSection.Props['description'];
    };
  } & Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'className' | 'style' | 'onChange'
  >;
}

export const Input = ({
  variant = 'primary',
  className,
  style,
  onChange = (value: string) => console.log(value),
  unit,
  label,
  ...props
}: Input.Props) => {
  const valueWidth = String(props.value).length;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <ConditionalWrap
      condition={!!label}
      wrap={children => (
        <FormSection title={label!.text} description={label!.helper}>
          {children}
        </FormSection>
      )}
    >
      <div className={css.container}>
        <input
          data-variant={variant}
          type="text"
          className={`${css.root} ${className}`}
          onChange={handleChange}
          style={style}
          {...props}
        />
        {unit && (
          <Text
            variant="textXs"
            color={colors.gray400}
            className={css.unit}
            style={{ left: `calc(${valueWidth}ch + ${Spacing.s5})` }}
          >
            {unit}
          </Text>
        )}
      </div>
    </ConditionalWrap>
  );
};
