import { CaptionChunk, CaptionChunkHighlight } from '@autocut/types/Captions';
import { PPRO_TEXT_LINE_BREAK } from './processCaptionsChunksState.utils';
import { WordBase } from '@autocut/types/Deepgram';

const splitTiming = (start: number, end: number): number => {
  return start + (end - start) / 2;
};

export const addWord = (
  chunks: CaptionChunk[],
  chunkIndex: number,
  wordIndex: number
): CaptionChunk[] => {
  const newChunks = [...chunks];
  const chunk = newChunks[chunkIndex];
  if (!chunk) return newChunks;

  // Get all words in the chunk
  const allWords = chunk.lines.flat();
  const existingWord = allWords[wordIndex];

  if (!existingWord) return newChunks;

  // Split the timings of the existing word into two equal parts
  const midTime = splitTiming(existingWord.start, existingWord.end);

  // Create the empty word with split timings
  const emptyWord: WordBase = {
    ...existingWord,
    id: `empty-${Date.now()}`,
    word: '',
    start: midTime,
    end: existingWord.end,
    punctuated_word: '',
  };

  // Update the existing word's start and end times
  const updatedExistingWord: WordBase = {
    ...existingWord,
    end: midTime,
  };

  // Update the chunk lines to insert the empty word
  const updatedLines = chunk.lines.map(line => {
    if (line.some(word => word.id === existingWord.id)) {
      const updatedLine = line.flatMap(word => {
        if (word.id === existingWord.id) {
          return [updatedExistingWord, emptyWord];
        }
        return word;
      });
      return updatedLine;
    }
    return line;
  });

  // Update text from updated lines
  const newText = updatedLines
    .map(words =>
      words.map(word => word.punctuated_word ?? word.word).join(' ')
    )
    .join(PPRO_TEXT_LINE_BREAK);

  // Update the highligts with the new word indices
  const highlightsWithUpdatedIndex = chunk.highlight.map((highlight, i) => {
    if (i <= wordIndex) {
      return highlight;
    }

    return {
      ...highlight,

      indexStart: highlight.indexStart + 2, // Adjust for the empty word
      indexEnd: highlight.indexEnd + 2,
    };
  });

  const existingWordHighlight = chunk.highlight[wordIndex];

  const newChunksHighlights: CaptionChunkHighlight[] = [
    ...highlightsWithUpdatedIndex.slice(0, wordIndex),
    {
      ...existingWordHighlight,
      end: midTime,
    },
    {
      indexStart: existingWordHighlight.indexEnd + 2,
      indexEnd: existingWordHighlight.indexEnd + 3,
      start: midTime,
      end: existingWordHighlight.end,
      word: '',
      lineBeforeWord:
        existingWordHighlight.lineBeforeWord +
        (existingWord.punctuated_word ?? existingWord.word) +
        ' ',
      confidence: 1,
      indexLine: existingWordHighlight.indexLine,
    },
    ...highlightsWithUpdatedIndex.slice(wordIndex + 1),
  ];

  // Replace the updated chunk in the array
  const updatedChunk: CaptionChunk = {
    ...chunk,
    text: newText,
    lines: updatedLines,
    nbLines: updatedLines.length,
    highlight: newChunksHighlights,
  };
  newChunks[chunkIndex] = updatedChunk;

  return newChunks;
};
