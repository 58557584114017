import { PreviewFrame } from '@autocut/components/atoms/PreviewFrame/PreviewFrame';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';
import * as React from 'react';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import ParameterLayout from '@autocut/layouts/ParametersGroupLayout/ParameterLayout';
import { InputRangeParameter } from '@autocut/components/atoms/Parameters/InputRangeParamater/InputRangeParameter';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import { useIntl } from 'react-intl';

export const PreviewZoomStep = () => {
  const { dynamicMinZoomIntensity, autoZoomMaxCoef } = useAutoCutStore(
    state => state.ui.parameters.zoom
  );
  const autoZoomMinCoef = 1 + dynamicMinZoomIntensity * (autoZoomMaxCoef - 1);
  const intl = useIntl();

  return (
    <FormSection
      style={{ paddingTop: Spacing.s4, paddingBottom: Spacing.s4 }}
      title={intl.formatMessage({
        id: 'autozoom_preview_title',
        defaultMessage: 'Zoom preview',
      })}
      description={intl.formatMessage({
        id: 'autozoom_preview_description',
        defaultMessage: 'Define the anchor point',
      })}
    >
      <FlexContainer
        style={{ width: '80%', margin: '0 auto', paddingBottom: Spacing.s2 }}
      >
        <PreviewFrame
          interfaces={[
            {
              type: 'anchor',
              additionalProps: {
                coeffZoomMin: autoZoomMinCoef,
                coeffZoomMax: autoZoomMaxCoef,
                onChange: (anchorPosMinCoef, anchorPosMaxCoef) => {
                  setAutocutStore(
                    'ui.parameters.zoom.anchorPosMinCoef',
                    anchorPosMinCoef
                  );
                  setAutocutStore(
                    'ui.parameters.zoom.anchorPosMaxCoef',
                    anchorPosMaxCoef
                  );
                },
              },
            },
          ]}
        />
      </FlexContainer>

      <ParameterLayout
        title={
          <TranslatedMessage
            id="autozoom_max_zoom"
            defaultMessage="Maximum zoom"
          />
        }
      >
        <InputRangeParameter
          value={autoZoomMaxCoef}
          step={0.05}
          min={1}
          max={2}
          unit="%"
          unitFormatting={value => `${(value * 100).toFixed()}`}
          onChange={value =>
            setAutocutStore(
              'ui.parameters.zoom.autoZoomMaxCoef',
              parseFloat(value)
            )
          }
        />
      </ParameterLayout>
    </FormSection>
  );
};
