import React, { useEffect, useState, useCallback, useRef } from 'react';

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { AutocutModeIds } from '@autocut/enums/modes.enum';
import { parametersValidationSchema } from '@autocut/validationSchemas/parameters.validationSchema';
import { useAutoCutStore } from '../../../hooks/useAutoCutStore';
import { FooterLayout } from '../../../layouts/FooterLayout';
import css from './ModeFooter.module.css';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import { colors } from '@autocut/designSystem/colors';

export namespace ModeFooter {
  export type Props = {
    renderButton: ({
      buttonRef,
      isLoading,
      isDisabled,
    }: {
      buttonRef: React.RefObject<HTMLButtonElement>;
      isLoading: boolean;
      isDisabled: boolean;
    }) => React.ReactElement;
    forcedErrorId?: string;
  };
  export type ErrorType = React.ReactNode | null;
}

export const ModeFooter = ({
  renderButton,
  forcedErrorId,
}: ModeFooter.Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { isProcessing, isSelectionLoading, mode, parameters } =
    useAutoCutStore(state => ({
      isProcessing: state.ui.process.isProcessing,
      isSelectionLoading: state.sequence.parsingProcess.isLoading,
      mode: state.ui.process.mode,
      parameters: state.ui.parameters,
    }));

  const [error, setError] = useState<ModeFooter.ErrorType>(null);

  const currentModeParameters = parameters[mode.id as AutocutModeIds];

  const validateParameters = useCallback<() => ModeFooter.ErrorType>(() => {
    const currentModeValidator =
      parametersValidationSchema[mode.id as AutocutModeIds];

    const error: string[] = [];
    if (forcedErrorId) error.push(forcedErrorId);

    try {
      currentModeValidator.parse(currentModeParameters);
    } catch (e: any) {
      const errorIds = [
        ...new Set(e.issues.map((issue: any) => issue.message)),
      ] as string[];

      error.push(...errorIds);
    }

    if (error.length === 0) return null;

    return <Error errorIds={error} />;
  }, [currentModeParameters, forcedErrorId, mode.id]);

  if (buttonRef.current) {
    // Check if the parameters are valid and prevent click if not
    buttonRef.current.onclick = e => {
      const error = validateParameters();
      if (error) {
        e.stopImmediatePropagation();
        setError(error);
      }

      if (isSelectionLoading || isProcessing) {
        e.stopImmediatePropagation();
      }
    };
  }

  useEffect(() => {
    if (error) {
      setError(null);
    }
  }, [currentModeParameters]);

  return (
    <FooterLayout>
      <FlexContainer
        className={css.container}
        flexDirection="column"
        gap="16px"
      >
        {error}

        <div className={css.cutButton}>
          {renderButton({
            buttonRef,
            isLoading: isSelectionLoading || isProcessing,
            isDisabled: isProcessing || error !== null,
          })}
        </div>
      </FlexContainer>
    </FooterLayout>
  );
};

const Error = ({ errorIds }: { errorIds: string[] }) => {
  return (
    <FlexContainer flexDirection="column" gap="4px">
      {errorIds.map(errorId => (
        <Text variant="textXs.medium" key={errorId} color={colors.error500}>
          <TranslatedMessage id={errorId} />
        </Text>
      ))}
    </FlexContainer>
  );
};
