import * as React from 'react';

import { enableBodyScroll } from 'body-scroll-lock';

import SilenceDetectionTypeChoice from '@autocut/components/atoms/SilenceDetectionTypeChoice/SilenceDetectionTypeChoice';
import { TourContainer } from '@autocut/components/containers/TourContainer';
import { NoiseLevelStep } from '@autocut/components/steps/NoiseLevelStep';
import { PaddingStep } from '@autocut/components/steps/PaddingStep';
import { PreviewStep } from '@autocut/components/steps/PreviewStep';
import { SelectedClipsStep } from '@autocut/components/steps/SelectedClipsStep/SelectedClipsStep';
import { SilenceSizeStep } from '@autocut/components/steps/SilenceSizeStep';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { onboardingLocalStorageKeys } from '@autocut/onboarding/onboardingLocalStorageKeys.enum';
import { legacySteps } from '@autocut/onboarding/steps/legacy.steps';
import { StatMode } from '@autocut/types/StatCategory.enum';
import { StatType } from '@autocut/types/StatType.enum';
import { sendStats } from '@autocut/utils';
import { handleCutLegacyProcess } from '@autocut/utils/cut/handleCutProcess';
import { setLocalStorage } from '@autocut/utils/localStorage.utils';
import { AiOutlineScissor } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import { ModeWrapper } from '../ModeWrapper';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { SilencesFooterButton } from '@autocut/components/atoms/SilencesFooterButton';
import { TransitionStep } from '@autocut/components/steps/TransitionStep/TransitionStep';
import { SilenceStep } from '@autocut/components/steps/SilenceStep/SilenceStep';

import silenceSelectionImageUrl from '@autocut/assets/images/selectionScreen/silence.gif';
import { Divider } from '@autocut/designSystem/components/atoms/Divider/Divider';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
const silenceSelectionImage = new URL(silenceSelectionImageUrl, import.meta.url)
  .href;

export const LegacySilencesMode = () => {
  const intl = useIntl();
  const { selectionInfos } = useAutoCutStore(state => ({
    selectionInfos: state.sequence.infos,
    license: state.user.license,
  }));

  return (
    <ModeWrapper
      Icon={AiOutlineScissor}
      text="AutoCut Silences"
      displayCondition={!!(selectionInfos && selectionInfos.nbClipsAudio > 0)}
      invalidSelectionScreen={{
        titleId: 'text_noClip_title',
        titleDefaultMessage: 'Select clip(s) to cut in your timeline',
        texts: [
          { id: 'text_noClip', defaultMessage: 'Nothing selected' },
          {
            id: 'text_noClip_no_selection',
            defaultMessage:
              'If the selected clips do not appear, try restarting AutoCut.',
          },
        ],
        linkId: 'silence_link_tuto',
        selectionImage: silenceSelectionImage,
      }}
    >
      <TourContainer
        steps={legacySteps}
        beforeClose={() => {
          setLocalStorage(onboardingLocalStorageKeys.Legacy, true);
          // @ts-ignore
          enableBodyScroll(document);
        }}
        afterOpen={async () => {
          await sendStats({
            type: StatType.ON_BOARDING_STARTED,
            value: 1,
            traits: {
              page: StatMode.LEGACY,
            },
          });
        }}
      >
        <FlexContainer flexDirection="column" gap={Spacing.s4}>
          <SilenceDetectionTypeChoice />
          <NoiseLevelStep modeId="silence" />
          <Divider />
          <SilenceSizeStep modeId="silence" />
          <Divider />
          <PaddingStep modeId="silence" />
          <Divider />
          <SilenceStep />
          <Divider />
          <TransitionStep modeId="silence" />
          <Divider />
          <SelectedClipsStep />
          <Divider />
          <PreviewStep modeId="silence" />
          <ModeFooter
            renderButton={({ buttonRef, isLoading, isDisabled }) => (
              <SilencesFooterButton
                ref={buttonRef}
                modeId="silence"
                isLoading={isLoading}
                isDisabled={isDisabled}
                onClickFunction={() => handleCutLegacyProcess(intl)}
              />
            )}
          />
        </FlexContainer>
      </TourContainer>
    </ModeWrapper>
  );
};
