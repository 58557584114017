import { evalTS } from '@autocut/lib/utils/bolt';
import { Speaker } from '@autocut/types/Speaker';
import { getParametersForMode } from '../parameters.utils';

export const getSpeakersWithClips = async () => {
  const { speakers } = getParametersForMode('podcast');
  const speakersWithClips: (Speaker & { clips: Clip[] })[] = [];

  for (const speaker of speakers) {
    const clips: Clip[] = await evalTS(
      'getTrackClipsByIndex',
      Number(speaker.audioTrack)
    );

    speakersWithClips.push({ ...speaker, clips });
  }

  return speakersWithClips;
};
