export type ColorKeys = keyof typeof colors;

export const colors = {
  // Primary
  primary100: 'var(--primary-100)',
  primary200: 'var(--primary-200)',
  primary300: 'var(--primary-300)',
  primary400: 'var(--primary-400)',
  primary500: 'var(--primary-500)',
  primary600: 'var(--primary-600)',
  primary700: 'var(--primary-700)',
  primary800: 'var(--primary-800)',
  primary900: 'var(--primary-900)',

  // Grays
  gray100: 'var(--gray-100)',
  gray200: 'var(--gray-200)',
  gray300: 'var(--gray-300)',
  gray400: 'var(--gray-400)',
  gray500: 'var(--gray-500)',
  gray600: 'var(--gray-600)',
  gray700: 'var(--gray-700)',
  gray800: 'var(--gray-800)',
  gray900: 'var(--gray-900)',

  // Error
  error100: 'var(--error-100)',
  error200: 'var(--error-200)',
  error300: 'var(--error-300)',
  error400: 'var(--error-400)',
  error500: 'var(--error-500)',
  error600: 'var(--error-600)',
  error700: 'var(--error-700)',
  error800: 'var(--error-800)',
  error900: 'var(--error-900)',

  // Success
  success100: 'var(--success-100)',
  success200: 'var(--success-200)',
  success300: 'var(--success-300)',
  success400: 'var(--success-400)',
  success500: 'var(--success-500)',
  success600: 'var(--success-600)',
  success700: 'var(--success-700)',
  success800: 'var(--success-800)',
  success900: 'var(--success-900)',
};
