import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

import { TranscriptChunkContainer } from '@autocut/components/containers/TranscriptChunkContainer';
import React, { useEffect, useState } from 'react';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import css from './CustomizeChunkCard.module.scss';
import { CaptionChunk } from '@autocut/types/Captions';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { CanvasFontParams } from '@autocut/utils/captions/canvas/canvas.utils';
import { splitChunk } from '@autocut/utils/captions/splitChunk.utils';
import { isInInterval, playChunk } from '@autocut/utils/captions/utils';
import { RxSpeakerLoud } from 'react-icons/rx';
import { setAutocutStore } from '@autocut/utils/zustand';
import { ModalType } from '@autocut/enums/modals.enum';
import { DeleteChunkButton } from './DeleteChunkButton/DeleteChunkButton';
import { EditableWord } from './EditableWord/EditableWord';
import { EmojiSelector } from '../../EmojiSelector/EmojiSelector';
import { onTextChange } from './utils';
import { addWord } from '@autocut/utils/captions/addWord.utils';
import { removeWord } from '@autocut/utils/captions/removeWord.utils';

export const CustomizeChunkCard = ({
  selectedChunkIndex,
  chunks,
  onChange,
}: {
  selectedChunkIndex: number | null;
  chunks: CaptionChunk[];
  onChange: (newChunks: CaptionChunk[]) => void;
}) => {
  const selectedChunk =
    selectedChunkIndex === null ? null : chunks[selectedChunkIndex];

  const [currentTimeout, setCurrentTimeout] = useState<
    NodeJS.Timeout | undefined
  >(undefined);

  useEffect(() => {
    if (currentTimeout) {
      clearTimeout(currentTimeout);
    }
  }, [selectedChunkIndex]);

  const {
    settings: { width: sequenceWidth },
    params,
    inPoint = null,
    outPoint = null,
  } = useAutoCutStore(state => ({
    settings: state.sequence.infos?.settings || state.sequence.lastSettings,
    params: state.ui.parameters.caption,
    inPoint: state.sequence.infos?.inPoint,
    outPoint: state.sequence.infos?.outPoint,
  }));

  const fontParameters: CanvasFontParams = {
    italic: params.formating.italic,
    fontSize: params.text.fontSize,
    fontFamily: params.text.font.fontFamily,
  };
  const allowedWidth = (params.formating.maxWidth / 100) * sequenceWidth;

  const isRtl = params.languageOfTranscription.textDirection === 'rtl';

  return (
    <TranscriptChunkContainer
      flexDirection="row"
      gap={12}
      justifyContent="space-between"
      alignItems="center"
      flexWrap="nowrap"
      className={css.container}
    >
      <FlexContainer
        flexDirection="column"
        gap={12}
        alignItems="flex-start"
        style={{
          width: '100%',
        }}
      >
        <FlexContainer
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
          gap={16}
        >
          <EmojiSelector
            selectedEmoji={
              selectedChunk?.emoji && selectedChunk?.emojiUrl
                ? {
                    name: selectedChunk?.emoji,
                    url: selectedChunk?.emojiUrl,
                  }
                : undefined
            }
            onEmojiSelect={emoji => {
              if (!selectedChunk) {
                return;
              }
              const updatedChunk: CaptionChunk = {
                ...selectedChunk,
                emoji: emoji.name,
                emojiUrl: emoji.url,
                emojiSize: emoji.size,
              };

              const newChunks = chunks.map((chunk, index) =>
                index === selectedChunkIndex ? updatedChunk : chunk
              );

              onChange(newChunks);
            }}
            onDeleteEmoji={() => {
              if (!selectedChunk) return;
              const updatedChunk: CaptionChunk = {
                ...selectedChunk,
                emoji: undefined,
                emojiUrl: undefined,
                emojiSize: undefined,
              };

              const newChunks = chunks.map((chunk, index) =>
                index === selectedChunkIndex ? updatedChunk : chunk
              );

              onChange(newChunks);
            }}
            disabled={!selectedChunk}
          />

          {selectedChunk && (
            <div
              className={css.playChunkIcon}
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                if (
                  (inPoint !== null || outPoint !== null) &&
                  !isInInterval(selectedChunk.start, selectedChunk.end, [
                    inPoint ?? 0,
                    outPoint ?? chunks[chunks.length - 1].end,
                  ])
                ) {
                  setAutocutStore(
                    'ui.currentErrorId',
                    'SET_CURSOR_OUT_INTERVAL'
                  );
                  setAutocutStore('ui.openedModalName', ModalType.Error);
                  return;
                }

                setCurrentTimeout(
                  await playChunk(
                    selectedChunk.start,
                    selectedChunk.end,
                    currentTimeout
                  )
                );
              }}
            >
              <RxSpeakerLoud size="20px" />
            </div>
          )}
        </FlexContainer>

        {selectedChunk && (
          <FlexContainer
            gap={4}
            alignItems="center"
            flexWrap="wrap"
            flexDirection={isRtl ? 'row-reverse' : 'row'}
            style={{
              alignSelf: isRtl ? 'flex-end' : 'flex-start',
            }}
            className={selectedChunk.deleted ? css.deletedChunk : ''}
          >
            {selectedChunk.highlight.map((highlight, index) => {
              return (
                <EditableWord
                  key={index}
                  highlight={highlight}
                  chunk={selectedChunk}
                  isRtl={isRtl}
                  onTextChange={(newValue: string) => {
                    if (!selectedChunk || selectedChunkIndex === null) return;

                    const newChunks = onTextChange({
                      newValue,
                      selectedChunk,
                      index,
                      chunks,
                      selectedChunkIndex,
                      allowedWidth,
                      fontParameters,
                      params,
                      highlight,
                    });

                    onChange(newChunks);
                  }}
                  onSplit={() => {
                    if (!selectedChunk || selectedChunkIndex === null) return;

                    const newChunks = splitChunk(
                      chunks,
                      selectedChunkIndex,
                      index
                    );
                    onChange(newChunks);
                  }}
                  onAddWord={() => {
                    if (!selectedChunk || selectedChunkIndex === null) return;

                    const newChunks = addWord(
                      chunks,
                      selectedChunkIndex,
                      index
                    );
                    onChange(newChunks);
                  }}
                  onRemoveWord={() => {
                    if (!selectedChunk || selectedChunkIndex === null) return;

                    const newChunks = removeWord(
                      chunks,
                      selectedChunkIndex,
                      index
                    );
                    onChange(newChunks);
                  }}
                  isLast={index === selectedChunk.highlight.length - 1}
                />
              );
            })}
          </FlexContainer>
        )}

        {!selectedChunk && (
          <p
            style={{
              color: '#ffffff',
              fontStyle: 'italic',
              fontSize: '14px',
            }}
          >
            <TranslatedMessage
              id="captions_customization_select_chunk"
              defaultMessage="Select the part of the transcript you want to edit/customize"
            />
          </p>
        )}
      </FlexContainer>

      <div
        style={{
          userSelect: selectedChunk ? 'all' : 'none',
          pointerEvents: selectedChunk ? 'all' : 'none',
        }}
      >
        <DeleteChunkButton
          onConfirm={() => {
            if (!selectedChunk) return;

            const updatedChunk: CaptionChunk = {
              ...selectedChunk,
              deleted: !selectedChunk.deleted,
            };

            const newChunks = chunks.map((chunk, index) =>
              index === selectedChunkIndex ? updatedChunk : chunk
            );

            onChange(newChunks);
          }}
          variant={selectedChunk?.deleted ? 'restore' : 'delete'}
        />
      </div>
    </TranscriptChunkContainer>
  );
};
