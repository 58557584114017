import * as React from 'react';
import { useRef, useState } from 'react';

import { MdOutlineEmail } from 'react-icons/md';
import { TiArrowLeft } from 'react-icons/ti';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { SingleValue } from 'react-select';

import './GetFreeTrialModal.css';
import { GET_MAC_ID_COMMAND } from '@autocut/constants/constants';
import { useLocale } from '@autocut/hooks/useLocale';
import { useLogger } from '@autocut/hooks/useLogger';
import { os } from '@autocut/lib/cep/node';
import logLevel from '@autocut/types/logLevel.enum';
import { SentryFingerPrintEnum } from '@autocut/types/SentryFingerPrint.enum';
import { exec } from '@autocut/utils/exec.utils';
import { autocutApi } from '@autocut/utils/http.utils';
import { Button, Input } from '../../atoms';
import { CustomSelectText } from '../../atoms/CustomSelectText/CustomSelectText';
import FlexContainer from '../../../designSystem/components/molecules/FlexContainer';
import { manageError } from '@autocut/utils/manageError';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { getUUID } from '@autocut/utils/system/uuid.system.utils';
import { getOS } from '@autocut/utils/system/os.system.utils';
import { getHostName } from '@autocut/utils/system/hostname.system.utils';

const DISCOVER_OPTIONS = [
  'facebook',
  'youtube-video',
  'youtube-comment',
  'reddit',
  'google',
  'friend',
  'school',
  'other',
];

interface FreeTrialModalProps {
  closeModal: () => void;
  openModal: React.Dispatch<React.SetStateAction<string | boolean>>;
  setAskingKeyEmail: (s: string) => void;
}

export const validateEmail = (value: string) => {
  let error: string | undefined = undefined;
  if (!value) {
    error = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
};

export const GetFreeTrialModal = ({
  closeModal,
  openModal,
  setAskingKeyEmail,
}: FreeTrialModalProps) => {
  const intl = useIntl();
  const [email, setEmail] = useState<string>('');
  const [discover, setDiscover] = useState('');
  const [error, setError] = useState<string>();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { locale } = useLocale();
  const { logMessage } = useLogger('GetFreeTrialModal');

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const handleSelectChange = (
    newValue: SingleValue<{ value: string; label: string }>
  ) => {
    logMessage(logLevel.debug, 'Discover option changed', { newValue });
    setDiscover(newValue === null ? '' : newValue.value);
  };

  const getFreeLicense = async () => {
    try {
      if (validateEmail(email) !== undefined) {
        setError(validateEmail(email));
        return;
      }

      const uuid = await getUUID();
      if (uuid === undefined) {
        logMessage(logLevel.error, "Can't read the computer's UUID");

        throw new Error(
          intl.formatMessage({
            id: "can't_read_uuid_error",
            defaultMessage: "Can't read the computer's UUID",
          })
        );
      }

      let pcName = await getHostName();

      const platform = os.platform();
      if (platform === 'darwin') {
        const { stdout } = await exec({
          command: GET_MAC_ID_COMMAND,
          sentryData: {
            fingerPrint: SentryFingerPrintEnum.EXEC.GET_MAC_ID,
            context: { shellCommand: GET_MAC_ID_COMMAND },
          },
        });
        pcName = stdout.trim();
      }

      if (pcName === undefined) {
        logMessage(logLevel.error, "Can't read the computer's name.");
        throw new Error(
          intl.formatMessage({
            id: "can't_read_host_error",
            defaultMessage: "Can't read the computer's name",
          })
        );
      }

      const params = {
        email: email,
        language: locale,
        discover: discover,
        pc_uuid: uuid,
        pc_name: pcName,
        os: getOS(),
      };

      logMessage(logLevel.notice, 'Asking server for new Trial key.', {
        params,
      });
      const { data } = await autocutApi.get(`/keys/generate-trial-key`, {
        params,
      });

      if (data === undefined) {
        setError(
          intl.formatMessage({
            id: "can't_reach_server_error",
            defaultMessage: 'Can not reach the server',
          })
        );
        logMessage(logLevel.error, "Can't reach the server.");
        return;
      }

      if (data.message === 'OK' || data.message === 'OK2') {
        setAskingKeyEmail(email);
        logMessage(logLevel.notice, 'Server has generated a key.');

        openModal('LicenseKey');
        logMessage(logLevel.info, 'Open UseLicenseKeyModal.');
      } else {
        logMessage(logLevel.warn, 'Server has not generated the key', { data });
        setError(data.message);
      }

      return;
    } catch (error: any) {
      setError(
        intl.formatMessage({
          id: 'asking_key_error',
          defaultMessage:
            'Error while asking server for key, please try again.\n',
        }) + error
      );
      manageError({
        error: new IncrementalError(error, 'GetFreeTrialModal'),
        disableModal: true,
      });
    }
    return;
  };

  const discoverOptions = DISCOVER_OPTIONS.map(option => ({
    value: option,
    label: intl.formatMessage({
      id: `discover-option-${option}`,
    }),
  }));

  return (
    <FlexContainer flexDirection="column" className="free-trial-container">
      <FlexContainer flexDirection="column" className="discover-container">
        <FlexContainer className="close-modal-container" alignItems="center">
          <span className="close-modal" onClick={closeModal}>
            <TiArrowLeft size={40} color="#FFFFFF" />
          </span>
          <h3 onClick={closeModal} className="close-modal">
            <TranslatedMessage id="button_back" defaultMessage="Back" />
          </h3>
        </FlexContainer>
        <FlexContainer className="title-container" alignItems="center">
          <h3>
            <TranslatedMessage
              id="text_GetLicence_Title"
              defaultMessage="Receive a free trial license by email"
            />
          </h3>
          <div className="key-circle">
            <MdOutlineEmail size={16} color="#FFFFFF" />
          </div>
        </FlexContainer>
        <p className="free-trial-text">
          <TranslatedMessage
            id="text_GetLicence_Select"
            defaultMessage="Where did you discover Autocut ?"
          />
        </p>
        <div className="discover-select">
          <CustomSelectText
            options={discoverOptions}
            handleSelectChange={handleSelectChange}
          />
        </div>
      </FlexContainer>

      <FlexContainer flexDirection="column" justifyContent="center">
        <p className="free-trial-text">
          <TranslatedMessage
            id="text_GetLicence_Text"
            defaultMessage="Enter your email address and receive a free trial license"
          />
        </p>
        <Input
          className="input-email-trial"
          placeholder="email@gmail.com"
          type="email"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          inputSize="md"
        />
      </FlexContainer>

      {error && (
        <div className="error-container">
          {error.split('\n').map((item, key) => (
            <>
              <span key={key}>{item}</span>
              <br />
            </>
          ))}
        </div>
      )}
      <div className="get-trial-button">
        <Button onClickFunction={getFreeLicense} ref={buttonRef}>
          <TranslatedMessage
            id="text_GetLicence_Button"
            defaultMessage="Get a free license"
          />
        </Button>
      </div>
    </FlexContainer>
  );
};
