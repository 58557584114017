import * as React from 'react';

import { useNavigate } from 'react-router-dom';
import { AutocutModes, AutocutModeState } from '@autocut/enums/modes.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import logLevel from '@autocut/types/logLevel.enum';
import css from './SilenceDetectionTypeChoice.module.css';
import { setAutocutStore } from '@autocut/utils/zustand';
import { Tabs } from '@autocut/designSystem/components/atoms/Tabs/Tabs';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import { useIntl } from 'react-intl';

const SilenceDetectionTypeChoice = ({}) => {
  const intl = useIntl();
  const { logMessage } = useLogger('SilenceDetectionTypeChoice');
  const { mode, license } = useAutoCutStore(state => ({
    mode: state.ui.process.mode,
    license: state.user.license,
  }));
  const navigate = useNavigate();

  const isAiAvailable =
    license?.features?.includes(AutocutModes.Ai.id) || false;

  // const { setIsOpen: setIsTourOpen } = useTour();
  // React.useEffect(() => {
  //   if (!getLocalStorage(onboardingLocalStorageKeys.ModeSelection)) {
  //     setIsTourOpen(true);
  //   }
  // }, []);

  return (
    <Tabs
      onChange={value => {
        if (value === 'ai' && !isAiAvailable) return;

        const values: { [key: string]: string } = {
          silence: 'legacy',
          ai: 'ai',
        };

        logMessage(logLevel.notice, 'Changing mode', {
          mode: values[value],
        });
        setAutocutStore(
          'ui.process.mode',
          Object.keys(AutocutModes).find(
            mode => AutocutModes[mode as keyof typeof AutocutModes].id === value
          ) as any as AutocutModeState
        );
        navigate(`/cut/${values[value]}`);
      }}
      value={mode.id}
      options={[
        {
          content: (
            <Item
              title="AutoCut Silences v2"
              subtitle={intl.formatMessage({
                id: 'silence_type_tab_legacy_subtitle',
                defaultMessage: 'Full Settings (recommended)',
              })}
            />
          ),
          value: AutocutModes.Legacy.id,
        },
        {
          content: (
            <Item
              title="AutoCut Silences AI"
              subtitle={intl.formatMessage({
                id: 'silence_type_tab_ai_subtitle',
                defaultMessage: 'Simplified Settings',
              })}
            />
          ),
          value: AutocutModes.Ai.id,
        },
      ]}
    />
  );
};

type ItemProps = {
  title: string;
  subtitle: string;
};

const Item = ({ title, subtitle }: ItemProps) => {
  return (
    <FlexContainer
      className={css.itemContainer}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Text variant="textMd.bold" color="white">
        {title}
      </Text>
      <Text variant="textXs" color="white">
        {subtitle}
      </Text>
    </FlexContainer>
  );
};

export default SilenceDetectionTypeChoice;
