import React, { HTMLAttributes, ReactElement } from 'react';
import FlexContainer from '../../molecules/FlexContainer';

import css from './Slider.module.scss';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import { Text } from '../Text/Text';
import { IconComponentProps } from '../Icon/Icon';
import { colors } from '@autocut/designSystem/colors';
import { SliderVariant } from './variants';

export type SliderProps = {
  value: string;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  unit?: React.ReactNode;
  iconLeft?: (props: IconComponentProps) => JSX.Element;
  iconRight?: (props: IconComponentProps) => ReactElement;
  variant?: SliderVariant;
  coloredTrackColor?: string;
} & Omit<HTMLAttributes<HTMLInputElement>, 'min' | 'max' | 'onChange'>;

export const Slider = ({
  value,
  onChange,
  min = 0,
  max = 100,
  iconLeft: IconLeft,
  iconRight: IconRight,
  variant = 'noValue',
  coloredTrackColor = undefined,
  unit,
  ...props
}: SliderProps) => {
  const range = max - min;

  const percentage = ((parseInt(value) - min) * 100) / range;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = parseInt(e.target.value);
    onChange(currentValue);
  };

  const formattedUnit = unit ? (
    <Text variant="textXs" color={colors.gray300}>
      {unit}
    </Text>
  ) : null;

  return (
    <FlexContainer
      data-variant={variant}
      gap={Spacing.s1}
      className={css.container}
      justifyContent="center"
      alignItems="center"
    >
      {IconLeft && (
        <IconLeft className={css.icon} size={24} color={colors.gray400} />
      )}
      {variant === 'valueOnLeft' && (
        <Text
          className={css.value}
          variant="textXs"
          color="white"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 0,
          }}
        >
          {value} {formattedUnit}
        </Text>
      )}
      <div className={css.innerContainer}>
        <input
          className={css.root}
          type="range"
          onChange={handleChange}
          min={min}
          max={max}
          {...props}
          value={parseInt(value)}
        />
        {coloredTrackColor && (
          <div
            className={css.coloredTrack}
            style={{
              width: `${percentage}%`,
              backgroundColor: coloredTrackColor,
            }}
          />
        )}
        {variant === 'valueOnKnob' && (
          <Text
            className={`${css.value} ${css.onKnob}`}
            style={{
              width: 'auto',
              left: `${percentage}%`,
              display: 'flex',
              alignItems: 'center',
              gap: 0,
            }}
            variant="textXs"
            color="white"
          >
            {value} {formattedUnit}
          </Text>
        )}
      </div>
      {variant === 'valueOnRight' && (
        <Text
          className={css.value}
          variant="textXs"
          color="white"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 0,
          }}
        >
          {value} {formattedUnit}
        </Text>
      )}
      {IconRight && (
        <IconRight className={css.icon} size={24} color={colors.gray400} />
      )}
    </FlexContainer>
  );
};
