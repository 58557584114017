import { Author } from '@autocut/components/atoms/Author/Author';
import { EAuthors } from '@autocut/components/atoms/Author/utils';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import Modal, { ModalProps } from '@autocut/layouts/ModalLayout/BaseModal';
import React from 'react';

export type AuthoredModalProps = {
  title: React.ReactNode;
  author: EAuthors;
  description: React.ReactNode;
  body?: React.ReactNode;
  opened: boolean;
} & Partial<ModalProps>;

export const AuthoredModal = ({
  title,
  author,
  description,
  body,
  opened,
  ...props
}: AuthoredModalProps) => {
  if (!opened) return null;

  return (
    <Modal
      title={title}
      closeOnClickOutside={false}
      withCloseButton={false}
      {...props}
    >
      <FlexContainer flexDirection="column" gap={12}>
        <FormSection title={<Author author={author} />}>
          <PaddedSection>{description}</PaddedSection>
        </FormSection>

        {body}
      </FlexContainer>
    </Modal>
  );
};
