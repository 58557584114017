import { CaptionChunk } from '@autocut/types/Captions';
import { WordBase } from '@autocut/types/Deepgram';

export const getWordsFromTranscript = (chunks: CaptionChunk[]): WordBase[] => {
  const wordsWithEmoji = chunks.flatMap(chunk =>
    chunk.lines.flatMap(line =>
      line.map(word => ({
        ...word,
        emoji: chunk.emoji,
        emojiSize: chunk.emojiSize,
        emojiUrl: chunk.emojiUrl,
      }))
    )
  );

  return wordsWithEmoji;
};
