import * as React from 'react';

import {
  AiOutlineMail,
  AiOutlineQuestion,
  AiOutlineUndo,
  AiOutlineUser,
} from 'react-icons/ai';
import { HiLogout } from 'react-icons/hi';
import { MdOutlineRateReview, MdReplay } from 'react-icons/md';
import { RiGitRepositoryLine } from 'react-icons/ri';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAutocutStore } from '@autocut/utils/zustand';

import { AUTOCUT_CONSTANTS } from '@autocut/constants/config';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import logLevel from '@autocut/types/logLevel.enum';
import { CURRENT_ENV } from '@autocut/utils/currentEnv.utils';
import { logout } from '@autocut/utils/license/logout';
import { logOutSentryUser } from '@autocut/utils/sentry.utils';
import { KebabIcon } from './KebabIcon';
import { MenuItem } from './MenuItem';
import css from './kebabMenu.module.css';
import { BsStars } from 'react-icons/bs';
import { ModalType } from '@autocut/components/modals';
import {
  AutocutModes,
  AutocutModesKeys,
  getModeByLocation,
} from '@autocut/enums/modes.enum';
import { parameterReset } from '@autocut/observers/parameterReset';
import { addModalToQueue } from '@autocut/utils/modal/modals.utils';
import { IoStatsChartSharp } from 'react-icons/io5';

export const KebabMenu = () => {
  const isLogged = useAutoCutStore(state => state.user.isLogged);
  const { logMessage } = useLogger('KebabMenu');
  const navigate = useNavigate();
  const location = useLocation();

  const intl = useIntl();

  const handleLogOut = () => {
    logMessage(logLevel.info, 'User Logged out.');
    logout("User clicked on 'Log out' button in the kebab menu.");
    logOutSentryUser();
    navigate('/');
  };

  const looseFocus = () => {
    // @ts-ignore
    document.activeElement.blur();
  };

  return (
    <div className={css.container} tabIndex={0}>
      <KebabIcon />
      <div className={css.menu}>
        <MenuItem
          icon={<AiOutlineUser />}
          label={intl.formatMessage({
            id: 'help_account',
            defaultMessage: 'Manage licence key',
          })}
          onClick={() => {
            looseFocus();
            logMessage(logLevel.info, 'User went to account page.');
            openLinkInBrowser(
              AUTOCUT_CONSTANTS[CURRENT_ENV].WEBAPP_ADDRESS,
              true
            );
          }}
        />{' '}
        <MenuItem
          icon={<IoStatsChartSharp />}
          label={intl.formatMessage({
            id: 'globals_defaults_components_KebabMenu_usageStatistics',
            defaultMessage: 'Usage statistics',
          })}
          onClick={() => {
            looseFocus();
            addModalToQueue(ModalType.UsageStats);
          }}
        />
        <MenuItem
          icon={<RiGitRepositoryLine />}
          label={intl.formatMessage({
            id: 'help_academy',
            defaultMessage: 'Help',
          })}
          onClick={() => {
            looseFocus();
            logMessage(logLevel.info, 'User went to academy');

            openLinkInBrowser(
              AUTOCUT_CONSTANTS[CURRENT_ENV].ACADEMY_ADDRESS[
                intl.locale.toUpperCase() as 'FR' | 'EN'
              ]
            );
          }}
        />
        <MenuItem
          icon={<AiOutlineQuestion />}
          label={intl.formatMessage({
            id: 'help_tuto',
            defaultMessage: 'Lost ? Look at the tutorial',
          })}
          onClick={() => {
            looseFocus();
            logMessage(logLevel.info, 'User went to tutorial.');
            openLinkInBrowser(
              intl.formatMessage({
                id: 'link_tuto',
                defaultMessage: 'https://www.youtube.com/watch?v=7MEK3rZQDHI',
              })
            );
          }}
        />
        <MenuItem
          icon={<MdOutlineRateReview />}
          label={intl.formatMessage({
            id: 'help_review',
            defaultMessage: 'Review AutoCut',
          })}
          onClick={() => {
            looseFocus();
            logMessage(logLevel.info, 'User opened Senja in browser.');
            openLinkInBrowser('https://senja.io/p/autocut/r/ikmgDE');
          }}
        />
        <MenuItem
          icon={<AiOutlineMail />}
          label={intl.formatMessage({
            id: 'help_bug',
            defaultMessage: 'Bug report',
          })}
          onClick={() => {
            looseFocus();
            logMessage(logLevel.info, 'User opened bug report modal.');
            addModalToQueue(ModalType.ReportIssue);
          }}
        />
        <MenuItem
          icon={<BsStars />}
          label={intl.formatMessage({
            id: 'feature_request',
            defaultMessage: 'Feature request',
          })}
          onClick={() => {
            looseFocus();
            logMessage(logLevel.info, 'User opened feature request modal.');
            addModalToQueue(ModalType.FeatureRequest);
          }}
        />
        <MenuItem
          label={intl.formatMessage({
            id: 'help_reset_settings',
            defaultMessage: 'Reset settings',
          })}
          onClick={() => {
            looseFocus();

            const mode = getModeByLocation(location.pathname);

            if (!mode) {
              Object.keys(AutocutModes).map((modeKey: string) => {
                const mode = AutocutModes[modeKey as AutocutModesKeys];

                setAutocutStore(
                  mode.parametersPath,
                  mode.defaultParameters as any
                );
              });

              return;
            }

            parameterReset.reset();

            setAutocutStore(mode.parametersPath, mode.defaultParameters as any);
          }}
          icon={<AiOutlineUndo />}
        />
        <MenuItem
          label={intl.formatMessage({
            id: 'backup_modal_title',
            defaultMessage: 'AutoCut Backup',
          })}
          onClick={() => {
            looseFocus();
            setAutocutStore('ui.openedModalName', ModalType.Backup);
          }}
          icon={<MdReplay />}
        />
        {isLogged && (
          <MenuItem
            icon={<HiLogout />}
            label={intl.formatMessage({
              id: 'help_logout',
              defaultMessage: 'Logout',
            })}
            onClick={() => {
              looseFocus();
              handleLogOut();
            }}
          />
        )}
      </div>
    </div>
  );
};
