import type { Props, StepMerged } from 'react-joyride';
import type { EAuthors } from '@autocut/components/atoms/Author/utils';
import type { AutoCutState } from '@autocut/utils/zustand';

export enum EOnboardingModes {
  WELCOME = 'welcome',
  CAPTIONS = 'captions',
}

export type OnboardingStep = {
  target: string;
  author: EAuthors;
  description?: React.ReactNode;
  onNextStep?: (nextStep: () => void) => void;
  onSkip?: null | ((skipOnboarding: () => void) => void);
  onElementClick?: (nextStep: () => void, quitTour: () => void) => void;
  onElementDisappear?: {
    target?: string[];
    callback: (nextStep: () => void, quitTour: () => void) => void;
  }[];
  onElementAppear?: {
    target?: string[];
    callback: (nextStep: () => void, quitTour: () => void) => void;
  }[];
  isSkipped?: (props: AutoCutState) => boolean;
  disableContent?: boolean;
  highlightTarget?: boolean;
} & Partial<Omit<StepMerged, 'target' | 'content'>>;

export type OnboardingTourProps = Partial<
  Omit<Props, 'steps'> & { steps: Partial<OnboardingStep>[] }
> & {
  openTour: () => void;
};
