import { Licence } from '@autocut/types/isKeyValidResponse';
import { isKeyValid } from '@autocut/utils/auth.utils';
import { handleTrialTask } from '../game/trialGamfication.util';
import { initInterceptors, setTokens } from '../http.utils';
import { getKey, setKey } from '../localStorage.utils';
import { createOrUpdateUser } from '../stats.util';
import { autocutStoreVanilla, setAutocutStore } from '../zustand';

export const login = async ({
  key,
  license,
  additionalInformations,
  accessToken,
  refreshToken,
}: {
  key: string;
  license: Licence;
  additionalInformations?: {
    displayOnboarding?: boolean;
    latestInvoice?: string;
    scoreRef?: string;
    hasTestimonial?: boolean;
  };
  accessToken: string;
  refreshToken: string;
}) => {
  setKey(key);
  setAutocutStore('user.key', key);

  setAutocutStore('user.isLogged', true);

  setAutocutStore('user.license', license);
  setAutocutStore('user.additionalInformations', s => ({
    ...s.user.additionalInformations,
    ...additionalInformations,
  }));

  setTokens({ accessToken, refreshToken });
  initInterceptors();
  setAutocutStore('user.accessToken', '');

  if (additionalInformations?.hasTestimonial) {
    await handleTrialTask('testimonial', false);
  }

  await createOrUpdateUser();
};

export const loginFromLocalStorage = async () => {
  let key = autocutStoreVanilla().user.key;
  if (!key) key = getKey(false);
  if (key) {
    const keyCheckResponse = await isKeyValid(key);

    if (keyCheckResponse.result) {
      const license = {
        ...keyCheckResponse.licence,
        key,
      };
      await login({
        ...keyCheckResponse.accessTokens,
        key,
        license,
        additionalInformations: keyCheckResponse.additionalInformations,
      });
    } else {
      setAutocutStore('user.key', '');
    }
  }
};
