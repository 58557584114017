import React from 'react';
import { EAuthors } from '@autocut/components/atoms/Author/utils';
import { EOnboardingTargets, getDataTourTarget } from './utils';
import type { OnboardingStep } from '../types';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

export enum ECaptionsOnboardingTargets {
  CAPTIONS_TRANSCRIPTION_BUTTON = 'captions-transcription-button',
  CAPTIONS_LANGUAGE_STEP = 'captions-language-step',
  CAPTIONS_TRANSCRIPT_STEP = 'captions-transcript-step',
  CAPTIONS_CUSTOMIZE_STYLE = 'captions-customize-style',
  CAPTIONS_CUSTOMIZATION_BUTTON = 'captions-customization-button',
  CAPTIONS_CUSTOMIZATION_MODAL = 'captions-customization-modal',
  CAPTIONS_CREATE_PRESET_MODAL = 'captions-create-preset-modal',
  CAPTIONS_PREVIEW_BUTTON = 'captions-preview-button',
  CAPTIONS_PREVIEW_MODAL = 'captions-preview-modal',
  CAPTIONS_TRANSCRIPTION_PART = 'captions-transcription-part',
  CAPTIONS_TRANSCRIPTION_MODAL = 'captions-transcription-modal',
  CAPTIONS_PROCESS_BUTTON = 'captions-process-button',
  CAPTIONS_RETRY_BUTTON = 'captions-retry-button',
}

export const CAPTIONS_ONBOARDING_STEPS: OnboardingStep[] = [
  {
    disableBeacon: true,
    target: getDataTourTarget(EOnboardingTargets.INVALID_SELECTION_SCREEN),
    author: EAuthors.ARTHUR,
    description: (
      <TranslatedMessage
        id="onboarding_invalid_selection_screen"
        defaultMessage="First, you need to select your clips in your Premiere Pro timeline"
      />
    ),
    isSkipped: props =>
      Boolean(props.sequence.infos && props.sequence.infos.nbClipsAudio > 0),
    onElementAppear: [
      {
        target: [getDataTourTarget(EOnboardingTargets.LANGUAGE_SELECTION)],
        callback: nextStep => {
          nextStep();
        },
      },
    ],
    onElementDisappear: [
      {
        target: [
          getDataTourTarget(ECaptionsOnboardingTargets.CAPTIONS_LANGUAGE_STEP),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
    placement: 'top',
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(EOnboardingTargets.LANGUAGE_SELECTION),
    author: EAuthors.ARTHUR,
    description: (
      <TranslatedMessage
        id="onboarding_language_selection"
        defaultMessage="You can select the language of your clips here."
      />
    ),
    onNextStep: nextStep => {
      nextStep();
    },
    onElementDisappear: [
      {
        target: [
          getDataTourTarget(ECaptionsOnboardingTargets.CAPTIONS_LANGUAGE_STEP),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
    onElementAppear: [
      {
        target: [getDataTourTarget(EOnboardingTargets.PROCESS_MODAL)],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
    placement: 'top',
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPTION_BUTTON
    ),
    description: (
      <TranslatedMessage
        id="onboarding_transcription_button"
        defaultMessage="Once you have verified the selected clips just above, you can transcribe your clips before adding your captions."
      />
    ),
    author: EAuthors.ARTHUR,
    onElementDisappear: [
      {
        target: [
          getDataTourTarget(ECaptionsOnboardingTargets.CAPTIONS_LANGUAGE_STEP),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
    onElementAppear: [
      {
        target: [getDataTourTarget(EOnboardingTargets.PROCESS_MODAL)],
        callback: nextStep => {
          nextStep();
        },
      },
    ],
    placement: 'top',
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_LANGUAGE_STEP
    ),
    disableContent: true,
    author: EAuthors.ARTHUR,
    onElementAppear: [
      {
        target: [
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPT_STEP
          ),
        ],
        callback: nextStep => {
          nextStep();
        },
      },
      {
        target: [getDataTourTarget(EOnboardingTargets.ERROR_MODAL)],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPT_STEP
    ),
    description: (
      <TranslatedMessage
        id="onboarding_captions_transcript_step"
        defaultMessage="Well done ! You have successfully transcribed your clips. Now, I will guide you through the customization of your captions."
      />
    ),
    author: EAuthors.ARTHUR,
    onNextStep: nextStep => {
      nextStep();
    },
    onElementDisappear: [
      {
        target: [
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPT_STEP
          ),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
    onElementAppear: [
      {
        target: [
          getDataTourTarget(EOnboardingTargets.PROCESS_MODAL),
          getDataTourTarget(ECaptionsOnboardingTargets.CAPTIONS_PREVIEW_MODAL),
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPTION_MODAL
          ),
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_CUSTOMIZATION_MODAL
          ),
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_CREATE_PRESET_MODAL
          ),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
    placement: 'center',
    highlightTarget: false,
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_CUSTOMIZATION_BUTTON
    ),
    description: (
      <TranslatedMessage
        id="onboarding_captions_customization_button"
        defaultMessage="Click on the 'Edit style' button to open the stylization modal."
      />
    ),
    author: EAuthors.ARTHUR,
    onElementDisappear: [
      {
        target: [
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPT_STEP
          ),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
    onElementAppear: [
      {
        target: [
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_CUSTOMIZATION_MODAL
          ),
        ],
        callback: nextStep => {
          nextStep();
        },
      },
      {
        target: [
          getDataTourTarget(EOnboardingTargets.PROCESS_MODAL),
          getDataTourTarget(ECaptionsOnboardingTargets.CAPTIONS_PREVIEW_MODAL),
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPTION_MODAL
          ),
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_CREATE_PRESET_MODAL
          ),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
    placement: 'top',
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_CUSTOMIZATION_MODAL
    ),
    description: (
      <TranslatedMessage
        id="onboarding_captions_customization_modal"
        defaultMessage="Here you will find all the tools to customize your captions. Go have fun by tweaking the parameters and create your very own style! {br} Then, you can close the modal by saving your style."
      />
    ),
    author: EAuthors.ARTHUR,
    onNextStep: nextStep => {
      nextStep();
    },
    onElementDisappear: [
      {
        target: [
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_CUSTOMIZATION_MODAL
          ),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
    placement: 'center',
    highlightTarget: false,
  },
  {
    disableBeacon: true,
    disableContent: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_CUSTOMIZATION_MODAL
    ),
    author: EAuthors.ARTHUR,
    onElementDisappear: [
      {
        target: [
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_CUSTOMIZATION_MODAL
          ),
        ],
        callback: nextStep => {
          nextStep();
        },
      },
    ],
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_PREVIEW_BUTTON
    ),
    description: (
      <TranslatedMessage
        id="onboarding_captions_preview_button"
        defaultMessage="Once you are done tweaking the style of your captions, you can click on the 'Position & size' button."
      />
    ),
    author: EAuthors.ARTHUR,
    onElementAppear: [
      {
        target: [
          getDataTourTarget(ECaptionsOnboardingTargets.CAPTIONS_PREVIEW_MODAL),
        ],
        callback: nextStep => {
          nextStep();
        },
      },
      {
        target: [
          getDataTourTarget(EOnboardingTargets.PROCESS_MODAL),
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPTION_MODAL
          ),
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_CREATE_PRESET_MODAL
          ),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
    onElementDisappear: [
      {
        target: [
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPT_STEP
          ),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
    placement: 'top',
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_PREVIEW_MODAL
    ),
    description: (
      <TranslatedMessage
        id="onboarding_captions_preview_modal"
        defaultMessage="This modal will allow you to place your captions on the screen and adjust their size.{br}It also show you the hypothetical position of the emojis. Don't be afraid, the emoji is just there to adjust its position on the screen. We will actually add or remove them later.{br}You can drag and drop the position of the captions directly on the preview!"
      />
    ),
    author: EAuthors.ARTHUR,
    onNextStep: nextStep => {
      nextStep();
    },
    placement: 'center',
    highlightTarget: false,
  },
  {
    disableBeacon: true,
    disableContent: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_PREVIEW_MODAL
    ),
    author: EAuthors.ARTHUR,
    onElementDisappear: [
      {
        target: [
          getDataTourTarget(ECaptionsOnboardingTargets.CAPTIONS_PREVIEW_MODAL),
        ],
        callback: nextStep => {
          nextStep();
        },
      },
    ],
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPTION_PART
    ),
    author: EAuthors.ARTHUR,
    description: (
      <TranslatedMessage
        id="onboarding_captions_transcription_part"
        defaultMessage="You can now customize your transcription by clicking on this part. We strongly recommend to check the transcription you are working on."
      />
    ),
    onElementAppear: [
      {
        target: [
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPTION_MODAL
          ),
        ],
        callback: nextStep => {
          nextStep();
        },
      },
      {
        target: [
          getDataTourTarget(EOnboardingTargets.PROCESS_MODAL),
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_CUSTOMIZATION_MODAL
          ),
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_CREATE_PRESET_MODAL
          ),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
    onElementDisappear: [
      {
        target: [
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPT_STEP
          ),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPTION_MODAL
    ),
    description: (
      <TranslatedMessage
        id="onboarding_captions_transcription_modal"
        defaultMessage="This is were you will be able to correct your transcription. You can also add punctuation, manage emojis and more to make your captions more engaging.{br}Close the modal whenever you feel ready!"
      />
    ),
    author: EAuthors.ARTHUR,
    onNextStep: nextStep => {
      nextStep();
    },
    placement: 'center',
    highlightTarget: false,
  },
  {
    disableBeacon: true,
    disableContent: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPTION_MODAL
    ),
    author: EAuthors.ARTHUR,
    onElementDisappear: [
      {
        target: [
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPTION_MODAL
          ),
        ],
        callback: nextStep => {
          nextStep();
        },
      },
    ],
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_PROCESS_BUTTON
    ),
    author: EAuthors.ARTHUR,
    description: (
      <TranslatedMessage
        id="onboarding_captions_process_button"
        defaultMessage="Once you are satisfied with your transcription, click on the button to generate your captions."
      />
    ),
    onElementAppear: [
      {
        target: [getDataTourTarget(EOnboardingTargets.PROCESS_MODAL)],
        callback: nextStep => {
          nextStep();
        },
      },
      {
        target: [
          getDataTourTarget(ECaptionsOnboardingTargets.CAPTIONS_PREVIEW_MODAL),
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPTION_MODAL
          ),
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_CUSTOMIZATION_MODAL
          ),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
    onElementDisappear: [
      {
        target: [
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPT_STEP
          ),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPTION_PART
    ),
    disableContent: true,
    author: EAuthors.ARTHUR,
    onElementAppear: [
      {
        target: [
          getDataTourTarget(ECaptionsOnboardingTargets.CAPTIONS_RETRY_BUTTON),
        ],
        callback: nextStep => {
          nextStep();
        },
      },
    ],
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(ECaptionsOnboardingTargets.CAPTIONS_RETRY_BUTTON),
    author: EAuthors.ARTHUR,
    description: (
      <TranslatedMessage
        id="onboarding_captions_retry_button"
        defaultMessage="Your captions have now been added to your Premiere pro timeline! If you notice an error in your transcription afterwards, you can still click on that button."
      />
    ),
    onElementAppear: [
      {
        target: [
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPT_STEP
          ),
        ],
        callback: nextStep => {
          nextStep();
        },
      },
    ],
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPT_STEP
    ),
    author: EAuthors.ARTHUR,
    description: (
      <TranslatedMessage
        id="onboarding_captions_retry_process"
        defaultMessage="Now you are back to the captions editor and you can correct and repeat the process over and over again until you are satisfied with your captions."
      />
    ),
    onElementDisappear: [
      {
        target: [
          getDataTourTarget(
            ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPT_STEP
          ),
        ],
        callback: (nextStep, quitTour) => {
          quitTour();
        },
      },
    ],
    onNextStep: nextStep => {
      nextStep();
    },
    highlightTarget: false,
    placement: 'center',
    onSkip: null,
  },
  {
    disableBeacon: true,
    target: getDataTourTarget(
      ECaptionsOnboardingTargets.CAPTIONS_TRANSCRIPT_STEP
    ),
    author: EAuthors.ARTHUR,
    description: (
      <TranslatedMessage
        id="onboarding_captions_end"
        defaultMessage="Well done! You have completed this onboarding 🎉 {br}You can now go and create engaging content through captioning on real content!{br}Don't hesitate to join our discord community to share your work with other talented video editors."
      />
    ),
    onNextStep: nextStep => {
      nextStep();
    },
    highlightTarget: false,
    placement: 'center',
    onSkip: null,
  },
];
