import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { AutoCutStateKeys, setAutocutStore } from '@autocut/utils/zustand';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {
  RadioForm,
  RadioFormProps,
} from '@autocut/designSystem/components/molecules/RadioForm/RadioForm';
import { Alert } from '@autocut/designSystem/components/atoms/Alert/Alert';
import { IconInfoCircle } from '@autocut/designSystem/components/atoms/Icon/general/IconInfoCircle';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';

export type TransitionStepProps = {
  modeId?: 'silence' | 'ai';
};

export const TransitionStep = ({ modeId = 'silence' }: TransitionStepProps) => {
  const intl = useIntl();
  const { transistions: transitionParameter, silences: silenceParameter } =
    useAutoCutStore(state => state.ui.parameters[modeId]);

  const radioOptions = useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'silence_step_transition_option_none',
          defaultMessage: 'None',
        }),
        name: 'none',
        value: 'none',
        defaultChecked: transitionParameter === 'none',
      },
      {
        title: intl.formatMessage({
          id: 'silence_step_transition_option_j_cut',
          defaultMessage: 'J-Cut',
        }),
        name: 'j_cut',
        value: 'j_cut',
        defaultChecked: transitionParameter === 'j_cut',
        disabled: silenceParameter !== 'delete',
      },
      {
        title: intl.formatMessage({
          id: 'silence_step_transition_option_l_cut',
          defaultMessage: 'L-Cut',
        }),
        name: 'l_cut',
        value: 'l_cut',
        defaultChecked: transitionParameter === 'l_cut',
        disabled: silenceParameter !== 'delete',
      },
      {
        title: intl.formatMessage({
          id: 'silence_step_transition_option_both',
          defaultMessage: 'J&L-Cut',
        }),
        name: 'both',
        value: 'both',
        defaultChecked: transitionParameter === 'both',
        disabled: silenceParameter !== 'delete',
      },
      {
        title: intl.formatMessage({
          id: 'silence_step_transition_option_constant_power',
          defaultMessage: 'Constant Power',
        }),
        name: 'constant_power',
        value: 'constant_power',
        defaultChecked: transitionParameter === 'constant_power',
        disabled: silenceParameter !== 'delete',
      },
    ],
    [transitionParameter, silenceParameter, intl]
  );

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'silence_step_transition_title',
        defaultMessage: 'Transitions',
      })}
      description={intl.formatMessage({
        id: 'silence_step_transition_helper',
        defaultMessage:
          'All cuts can be J-Cut, L-Cut or both, if desired. This creates a more dynamic transition effect.',
      })}
    >
      <FlexContainer flexDirection="column" gap={Spacing.s3}>
        {silenceParameter !== 'delete' && (
          <Alert icon={<IconInfoCircle size={18} color="gray.300" />}>
            {intl.formatMessage({
              id: 'silence_step_transition_warning',
              defaultMessage:
                'You cannot use transitions without deleting silences.',
            })}
          </Alert>
        )}

        <RadioForm
          flexDirection="row"
          justifyContent="space-between"
          options={radioOptions}
          onChange={newValue =>
            setAutocutStore(
              `ui.parameters.${modeId}.transistions` as AutoCutStateKeys,
              newValue as typeof transitionParameter
            )
          }
        />
      </FlexContainer>
    </FormSection>
  );
};
