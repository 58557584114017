import React from 'react';

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { setAutocutStore } from '@autocut/utils/zustand';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { CameraComponent } from './Camera/Camera';
import { addCameraReducer } from '@autocut/utils/cameras/addCameraReducer';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { FaPlus } from 'react-icons/fa6';
import css from './CameraStep.module.scss';

export const CamerasStep = () => {
  const cameras = useAutoCutStore(state => state.ui.parameters.podcast.cameras);

  return (
    <FormSection
      title={
        <TranslatedMessage
          id="text_podcast_camera_title"
          defaultMessage="Video tracks"
        />
      }
      description={
        <TranslatedMessage
          id="text_podcast_camera_helper"
          defaultMessage="For each video track, select all the speakers that we can see on the screen."
        />
      }
    >
      <PaddedSection>
        <FlexContainer
          className={css.camerasContainer}
          flexDirection="column"
          gap={8}
        >
          {cameras.map(camera => (
            <CameraComponent camera={camera} key={camera.id} />
          ))}

          <Button
            variant="secondary.dashed"
            onClick={() => {
              setAutocutStore(
                'ui.parameters.podcast.cameras',
                addCameraReducer
              );
            }}
          >
            <FlexContainer
              flexDirection="row"
              gap={8}
              justifyContent="center"
              alignItems="center"
            >
              <FaPlus size={16} color="white" />
              <TranslatedMessage
                id="text_podcast_add_camera"
                defaultMessage="Add a camera"
              />
            </FlexContainer>
          </Button>
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};
