import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import { RadioForm } from '@autocut/designSystem/components/molecules/RadioForm/RadioForm';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

export type SilenceStepProps = {
  modeId?: 'repeat' | 'silence' | 'ai';
};

export const SilenceStep = ({ modeId = 'silence' }: SilenceStepProps) => {
  const intl = useIntl();
  const cuttingParameters = useAutoCutStore(
    state => state.ui.parameters[modeId]
  );
  const targetFormatted = useMemo(
    () =>
      intl.formatMessage({
        id: `text_${modeId === 'repeat' ? 'repetitions' : 'silences'}`,
        defaultMessage: 'silences',
      }),
    [intl, modeId]
  );

  return (
    <FormSection
      title={intl
        .formatMessage({
          id: 'title_silences_step',
          defaultMessage: 'Manage silences',
        })
        .replace('{mode}', targetFormatted)}
    >
      <PaddedSection>
        <RadioForm
          options={[
            {
              title: intl
                .formatMessage({
                  id: 'text_silences_step_delete',
                  defaultMessage: 'Remove silences',
                })
                .replace('{mode}', targetFormatted),
              name: 'delete',
              value: 'delete',
              defaultChecked:
                cuttingParameters.silences === 'delete' ||
                !cuttingParameters.silences,
            },
            {
              title: intl
                .formatMessage({
                  id: 'text_silences_step_keep',
                  defaultMessage: 'Keep silences',
                })
                .replace('{mode}', targetFormatted),
              name: 'keep',
              value: 'keep',
              defaultChecked: cuttingParameters.silences === 'keep',
            },
            {
              title: intl
                .formatMessage({
                  id: 'text_silences_step_mute',
                  defaultMessage: 'Mute silences',
                })
                .replace('{mode}', targetFormatted),
              name: 'mute',
              value: 'mute',
              defaultChecked: cuttingParameters.silences === 'mute',
            },
            {
              title: intl
                .formatMessage({
                  id: 'text_silences_step_delete_keep_spaces',
                  defaultMessage: 'Remove silences but keep spaces',
                })
                .replace('{mode}', targetFormatted),
              name: 'delete_keep_spaces',
              value: 'delete_keep_spaces',
              defaultChecked:
                cuttingParameters.silences === 'delete_keep_spaces',
            },
          ]}
          onChange={newValue => {
            setAutocutStore(
              `ui.parameters.${modeId}.silences`,
              newValue as typeof cuttingParameters.silences
            );

            if (newValue !== 'delete') {
              setAutocutStore(`ui.parameters.${modeId}.transistions`, 'none');
            }
          }}
        />
      </PaddedSection>
    </FormSection>
  );
};
