import { Tabs } from '@autocut/components/atoms/Tabs/Tabs';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import React from 'react';
import { FaFont } from 'react-icons/fa6';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { MdOutlineFormatSize } from 'react-icons/md';
import { AnimationIcon } from './Parts/CaptionsSidebar/icons/AnimationIcon';
import { TransitionIcon } from './Parts/CaptionsSidebar/icons/TransitionIcon';
import { OutlineIcon } from './Parts/CaptionsSidebar/icons/OutlineIcon';
import { BoxIcon } from './Parts/CaptionsSidebar/icons/BoxIcon';

import css from './CaptionsCustomizationModal.module.scss';
import TextCustomization from './Parts/TextCustomization/TextCustomization';
import AnimationsCustomization from './Parts/AnimationsCustomization/AnimationsCustomization';
import TransitionCustomization from './Parts/TransitionCustomization/TransitionCustomization';
import TextFormatCustomization from './Parts/TextFormatCustomization/TextFormatCustomization';
import OutlineCustomization from './Parts/OutlineCustomization/OutlineCustomization';
import BoxesCustomization from './Parts/BoxesCustomization/BoxesCustomization';
import { setAutocutStore } from '@autocut/utils/zustand';
import { ModalType } from '@autocut/enums/modals.enum';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { addModalToQueue } from '@autocut/utils/modal/modals.utils';
import TextPreview from './Parts/ExampleCaption/TextPreview';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { colors } from '@autocut/designSystem/colors';
import { ECaptionsOnboardingTargets } from '@autocut/context/OnboardingProvider/OnboardingSteps/CaptionsOnboardingSteps';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { processCaptionsChunksState } from '@autocut/utils/captions/processCaptionsChunksState.utils';
import { getWordsFromTranscript } from '../../utils/chunks.captions.utils';

export const CaptionsCustomizationModal = () => {
  const chunks = useAutoCutStore(state => state.onGoingProcess.captionChunks);

  const closeModal = () => {
    const words = getWordsFromTranscript(chunks ?? []);

    processCaptionsChunksState(words);
    setAutocutStore('ui.openedModalName', '');
  };
  return (
    <Modal
      data-tour={ECaptionsOnboardingTargets.CAPTIONS_CUSTOMIZATION_MODAL}
      title={
        <TranslatedMessage
          id="captions_customization_modal_title"
          defaultMessage="Edit style"
        />
      }
      icon={<MdOutlineModeEditOutline size="1.5rem" />}
      fullScreen
      withCloseButton={false}
      closeOnClickOutside={false}
      shouldScroll={false}
      footer={
        <FlexContainer
          flexDirection="row"
          gap={12}
          justifyContent="space-between"
          style={{
            paddingInline: 16,
          }}
        >
          <Button onClick={closeModal} variant="secondary">
            <TranslatedMessage
              id="captions_customization_presets_apply"
              defaultMessage="Apply style"
            />
          </Button>
          <Button
            onClick={() => {
              addModalToQueue(ModalType.CreatePresetModal);
              closeModal();
            }}
            color={colors.primary600}
          >
            <TranslatedMessage
              id="captions_customization_presets_apply_and_save"
              defaultMessage="Apply style and save as preset"
            />
          </Button>
        </FlexContainer>
      }
      closeModalFunction={closeModal}
    >
      <FlexContainer
        flexDirection="column"
        gap={24}
        style={{
          height: '100%',
        }}
      >
        <TextPreview />

        <Tabs
          content={[
            {
              TabContent: (
                <div className={css.iconContainer}>
                  <FaFont size={16} color="#F2F4F7" />
                </div>
              ),
              Panel: <TextCustomization />,
              value: 'font',
            },
            {
              TabContent: (
                <div className={css.iconContainer}>
                  <AnimationIcon size={20} color="#F2F4F7" strokeWidth={3} />
                </div>
              ),
              Panel: <AnimationsCustomization />,
              value: 'animation',
            },
            {
              TabContent: (
                <div className={css.iconContainer}>
                  <TransitionIcon
                    size={20}
                    color="#F2F4F7"
                    strokeWidth={3}
                    fill="#1D2939de"
                  />
                </div>
              ),
              Panel: <TransitionCustomization />,
              value: 'transition',
            },
            {
              TabContent: (
                <div className={css.iconContainer}>
                  <MdOutlineFormatSize size={20} color="#F2F4F7" />
                </div>
              ),
              Panel: <TextFormatCustomization />,
              value: 'text-format',
            },
            {
              TabContent: (
                <div className={css.iconContainer}>
                  <OutlineIcon size={16} color="#F2F4F7" strokeWidth={2} />
                </div>
              ),
              Panel: <OutlineCustomization />,
              value: 'outline',
            },
            {
              TabContent: (
                <div className={css.iconContainer}>
                  <BoxIcon size={20} color="#F2F4F7" strokeWidth={2} />
                </div>
              ),
              Panel: <BoxesCustomization />,
              value: 'box',
            },
          ]}
        />
      </FlexContainer>
    </Modal>
  );
};
