import * as React from 'react';

import { useIntl } from 'react-intl';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';

import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { Slider } from '@autocut/designSystem/components/atoms/Slider/Slider';
import { IconMinusCircle } from '@autocut/designSystem/components/atoms/Icon/general/IconMinusCircle';
import { IconPlusCircle } from '@autocut/designSystem/components/atoms/Icon/general/IconPlusCircle';

const AIAgressivenessStep = () => {
  const agressivenessParameter = useAutoCutStore(
    state => state.ui.parameters.ai.aggressivenessLevel
  );

  const intl = useIntl();

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'silence_step_aggressiveness_title',
        defaultMessage: 'AI aggressiveness',
      })}
      description={intl.formatMessage({
        id: 'silence_step_aggressiveness_helper',
        defaultMessage:
          'Aggressiveness will determine how much silence the AI will remove, minimum is 1 and maximum is 4',
      })}
    >
      <Slider
        min={1}
        max={4}
        value={`${agressivenessParameter + 1}`}
        onChange={value => {
          setAutocutStore(
            'ui.parameters.ai.aggressivenessLevel',
            Number(value) - 1
          );
        }}
        variant="valueOnKnob"
        coloredTrackColor="var(--primary-400)"
        iconLeft={() => <IconMinusCircle size={24} />}
        iconRight={() => <IconPlusCircle size={24} />}
      />
    </FormSection>
  );
};

export default AIAgressivenessStep;
