import * as React from 'react';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { Button } from '../Buttons/Button';
import css from './resizeFooterButton.module.css';

export type FooterButtonProps = {
  isLoading?: boolean;
  isDisabled?: boolean;
  onClickFunction: (...args: any) => void;
};

export const ResizeFooterButton = React.forwardRef<
  HTMLButtonElement,
  FooterButtonProps
>(({ isLoading = false, isDisabled = false, onClickFunction }, ref) => {
  return (
    <div id="resize-step9" className={css.container}>
      <Button
        ref={ref}
        isLoading={isLoading}
        disabled={isDisabled}
        onClickFunction={onClickFunction}
      >
        <TranslatedMessage
          id="resize_button"
          defaultMessage="Generate social clip"
        />
      </Button>
    </div>
  );
});
