import { NumberInput } from '@autocut/components/atoms/NumberInput';
import { InputRangeParameter } from '@autocut/components/atoms/Parameters/InputRangeParamater/InputRangeParameter';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import { AutocutModes } from '@autocut/enums/modes.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import ParameterLayout from '@autocut/layouts/ParametersGroupLayout/ParameterLayout';
import { ParametersGroupLayout } from '@autocut/layouts/ParametersGroupLayout/ParametersGroupLayout';
import { updateCoverage } from '@autocut/utils/zoom/updateCoverage';
import { setAutocutStore } from '@autocut/utils/zustand';
import * as React from 'react';
import { useIntl } from 'react-intl';

export const SmoothZoomStep = () => {
  const { zoomTypes, smoothZoomPercentage, nervousness, smoothMaxZoomTime } =
    useAutoCutStore(state => state.ui.parameters.zoom);
  const isOnlyZoomType =
    Object.values(zoomTypes).filter(value => value === true).length === 1
      ? true
      : false;
  const intl = useIntl();

  return (
    <FlexContainer
      style={{ paddingTop: Spacing.s4, paddingBottom: Spacing.s4 }}
    >
      <ParametersGroupLayout
        title={intl.formatMessage({
          id: 'autozoom_smooth_zoom',
          defaultMessage: 'Smooth zooms',
        })}
        status={zoomTypes.SMOOTH}
        onStatusChange={status => {
          setAutocutStore('ui.parameters.zoom.zoomTypes.SMOOTH', status);
          if (status)
            updateCoverage(
              'smoothZoomPercentage',
              AutocutModes.Zoom.defaultParameters.smoothZoomPercentage
            );
          else updateCoverage('smoothZoomPercentage', 0);
        }}
        hiddenContent={null}
      >
        <ParameterLayout
          title={
            <TranslatedMessage
              id="autozoom_smooth_zoom_coverage"
              defaultMessage="Smooth zooms coverage"
            />
          }
          grow
        >
          <InputRangeParameter
            value={smoothZoomPercentage}
            step={0.01}
            min={0}
            max={1}
            disabled={isOnlyZoomType}
            unit="%"
            unitFormatting={value => `${(value * 100).toFixed()}`}
            onChange={value =>
              updateCoverage('smoothZoomPercentage', parseFloat(value))
            }
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id="autozoom_smooth_zoom_nervousness"
              defaultMessage="Nervousness"
            />
          }
          grow
        >
          <InputRangeParameter
            value={nervousness}
            step={0.01}
            min={0}
            max={1}
            unit="%"
            unitFormatting={value => `${(value * 100).toFixed()}`}
            onChange={value =>
              setAutocutStore(
                'ui.parameters.zoom.nervousness',
                parseFloat(value)
              )
            }
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id="autozoom_max_zoom_title"
              defaultMessage="Maximum zoom duration"
            />
          }
          grow
          height={32}
        >
          <NumberInput
            unitId="secondUnit"
            value={`${smoothMaxZoomTime}`}
            targetedParameter={'smoothMaxZoomTime'}
            onChange={(value: string) =>
              setAutocutStore(
                'ui.parameters.zoom.smoothMaxZoomTime',
                parseFloat(value)
              )
            }
            inputContainerStyle={{ paddingTop: 6, paddingBottom: 6 }}
          />
        </ParameterLayout>
      </ParametersGroupLayout>
    </FlexContainer>
  );
};
